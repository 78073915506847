import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import StoryLines from '../utility/StoryLines';
import UserDetailsOverlay from './overlays/UserDetailsOverlay';
import { AuthContext } from '../auth/AuthContext';

function Header() {
  const { user } = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState(false);
  const [clickCount, setClickCount] = useState(0);

  const handleLogoClick = () => {
    if (user){
    setClickCount(prevCount => prevCount + 1);

    setTimeout(() => {
      setClickCount(0);
    }, 2000);

    if (clickCount >= 4) {
      setShowPopup(true);
      setClickCount(0);
    }}
  };

  return (
    <header className="header-footer">
      <div className="w100" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span>story2go</span>
        <StoryLines/>
        {(user && user.userrole) === 1 && <Link to="/">Home</Link>}
        {(user && user.userrole) === 1 && <Link to="/admin">Admin</Link>}
        <img src={"/assets/logo/logo2.png"} style={{ width: '3rem' }} alt="Logo SBAE GmbH" onClick={handleLogoClick} />
        <UserDetailsOverlay visible={showPopup} onClose={() => setShowPopup(false)} />
      </div>
    </header>
  );
}

export default Header;
