import React, { useState, useEffect, useCallback } from 'react';
import {  Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const UserManagerMain = () => {

    return(
        <div>
            <h1>User Manager</h1>
            <h3>UNDER CONSTRUCTION</h3>
            <ul>
                <li><Link to="./addUser">Nutzer Hinzufügen</Link></li>
                <li><Link to="./addUserStoryline">Nutzer Promptgroup freischalten</Link></li>
                <li><Link to="./userDetails/">Nutzer Details</Link></li>
            </ul>
            
        </div>
    )
};

export default UserManagerMain;
    