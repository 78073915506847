import { Link } from 'react-router-dom';

const AdminMainPage = () => {
return (
    <div>

    <h1>Admin</h1>
    <ul>
      <li><Link to="./prompts">Promps</Link></li>
      <li><Link to="./inputModule">Module</Link></li>
      <li><Link to="./inputType">Input</Link></li>
      <li><Link to="./prompts/functions">Funktionen</Link></li>

    </ul>
    <ul>
      <li><Link to="./models">Modelle</Link></li>
      <li><Link to="./voices">Stimmen</Link></li>
    </ul>
    <ul>
      <li><Link to="./promocodes">Promocodes</Link></li>
      <li><Link to="./userManager">User Manager</Link></li>
      <li><Link to="./userManager/userDetails/">User Details</Link></li>
    </ul>
    <ul>
      <li><Link to="./feedback/">Feedback</Link></li>
    </ul>
  </div>
  );
};

export default AdminMainPage;
