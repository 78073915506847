import React, { useState, useEffect, useContext} from 'react';
import axios from 'axios';
import { AuthContext } from '../auth/AuthContext';
//import ProgressOverlay from './overlays/ProgressOverlay';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import useAudioPlayer from '../hooks/useAudioPlayer';
import PlayerCard from '../utility/PlayerCard';
import StartCard from '../utility/StartCard';
import EndCard from '../utility/EndCard';
import MainInputs from './MainInputs';
import { jwtDecode } from 'jwt-decode';

function MainPlayer({userUUID}) {
  const [storyHistory, setStoryHistory] = useState([ ]);
  const { user, storyLine } = useContext(AuthContext);
  const token = localStorage.getItem('token');
  let decodedToken;

  if (token){
    decodedToken = jwtDecode(token);
  }


  const {
    currentAudio,
    audioPlayerRef,
    audioProgress,
    currentPlayingId,
    currentPausedId,
    handlePlayAudio,
    handlePlay,
    handlePause,
    handleListen,
    handleEnded,
    handleStop
} = useAudioPlayer();




const fetchStoryHistory = async () => {
  try {

    const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/stories/history/', {
      headers: {
        'Authorization': `Bearer ${token}`, // Stellen Sie sicher, dass 'token' hier Ihren gültigen Token enthält
      },
      params: {
        'storyLine': storyLine,
      }
    });

    if (response.status === 200 || response.status === 201){
      setStoryHistory(response.data);
    } else {
      console.error('Fehler beim Abrufen der bisherigen Stories.');
    }
  } catch (error) {
    console.error('Fehler beim Laden der bisherigen Stories:', error);
  }
};

useEffect(() => {
  if (storyLine){
    fetchStoryHistory();}
}, [storyLine]);


const changePlayerCardStatus = (processId) => {
  setStoryHistory(prevStoryHistory =>
    prevStoryHistory.map(item =>
      item.processId === processId ? { ...item, cardStatus: 2 } : item
    )
  );
};

const startNewProcess = (responseData) => {
  if (responseData.length > 0 && responseData[0]['status'] === 'success') {
    responseData.forEach(resp => {
      const newStoryItem = {
        processId: resp.processUUID,
        title: resp.title,
        cover: resp.cover,
        audiofile: resp.audiofile,
        cardStatus: resp.cardStatus,
      };
      setStoryHistory(prevStoryHistory => [...prevStoryHistory, newStoryItem]);
    });
  }
};



return (
  <div className='main-container'>
    {storyHistory.map((audioCard, index) => {
      const isStartCard = audioCard.processId === 'start';
      const isEndCard = audioCard.processId === 'end' && storyHistory[storyHistory.length - 2]?.cardStatus === 2;
      const isPlayerCard = audioCard.cardStatus > 0 && !isStartCard && !isEndCard;

      return (
        <React.Fragment key={audioCard.processId || index}>
          {isStartCard && <StartCard />}
          {isEndCard && <EndCard />}
          {isPlayerCard && (
            <PlayerCard
              userUUID={userUUID}
              processId={audioCard.processId}
              title={audioCard.title}
              cover={audioCard.cover}
              audiofile={audioCard.audiofile}
              onPlayAudio={handlePlayAudio}
              onStopAudio={handleStop}
              audioProgress={audioProgress}
              cardStatus={audioCard.cardStatus}
              currentPlayingId={currentPlayingId}
              currentPausedId={currentPausedId}
              onPlayerEnded={changePlayerCardStatus}
            />
          )}
        </React.Fragment>
      );
    })}

    {storyHistory.length > 0 && storyHistory[storyHistory.length - 1].cardStatus === 2 && (
      <MainInputs
        key={`${storyHistory[storyHistory.length - 1].processId}-${storyHistory.length}`}
        userUUID={userUUID}
        lastProcessId={storyHistory[storyHistory.length - 1].processId}
        onGotServerResponse={startNewProcess}
      />
    )}

    <AudioPlayer
      className='main-audo-player'
      ref={audioPlayerRef}
      src={currentAudio}
      onPlay={handlePlay}
      onPause={handlePause}
      onEnded={handleEnded}
      onListen={handleListen}
      style={(decodedToken.role===1||decodedToken.role===2 )? { display: 'block' }:{ display: 'none' }} // Anpassung nach Bedarf
    />
  </div>
);
}

export default MainPlayer;