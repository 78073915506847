import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import formatDate from '../../../utility/dateUtils';
import EditableField from '../../../utility/EditableField';
import { useNotification } from '../../../hooks/useNotifications';
import { Card, Button } from 'react-bootstrap';

const UserDetails = () => {
    const [userData, setUserData] = useState(null);
    const [userStoryLines, setUserStoryLines] = useState(null);
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();
    const [password, setPassword] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/${id}`, {
                    headers: { 'authorization': `Bearer ${token}` }
                });
                setUserData(userResponse.data[0]);

                const storyLineResponse = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/${id}/storylines`, {
                    headers: { 'authorization': `Bearer ${token}` }
                });
                setUserStoryLines(storyLineResponse.data);
            } catch (error) {
                showNotification('Fehler beim Laden der Daten', 'danger');
            }
        };
        fetchData();
    }, []);

    const handleSaveChanges = (newValue, fieldName) => {
        if (!newValue) {
            showNotification('Feld darf nicht leer sein', 'warning');
            return;
        }
        setUserData(prevData => ({ ...prevData, [fieldName]: newValue }));
    };

    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/${userData.id}`, userData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                showNotification('Änderungen gespeichert', 'success');
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
        }
    };

    const updatePw = async (newPw) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/${userData.id}/newPw`, {'id': id, 'newPw': newPw}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });

            if (response.status === 200) {
                setPassword('');
                showNotification('neues Passwort gespeichert', 'success');
            } else {
                showNotification('Fehler beim ändern des Passworts', 'danger');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
        }
    };

    if (!userData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Name:
                    <Card.Title>
                        <h2><EditableField value={userData.username} onSave={(newValue) => handleSaveChanges(newValue, 'username')} fieldType="text" /></h2>
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Rolle:
                    <Card.Title>
                        <EditableField value={userData.role} onSave={(newValue) => handleSaveChanges(newValue, 'role')} fieldType="number" />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
            <Card.Title>
                neues Passwort
             </Card.Title>
             <Card.Body>
                 <input  
                    type="text"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} 
                />
                {(password.trim().length > 3) && <Button onClick={() => updatePw(password)}>+</Button> }
                
             </Card.Body>
            </Card>
            { userStoryLines &&
            <Card>

            Storylines:
            <ul>
                {userStoryLines.map(storyLine => (
                    <li key={storyLine.storyline}>Name : {storyLine.name} | 
                    Storyline: {storyLine.storyline} | 
                    letzer Eintrag : {formatDate(storyLine.created_at, 'date-time')} | 
                     <Link to={`../userManager/inspectStoryline/${encodeURIComponent(storyLine.storyline)}`}>ansehen</Link> | 
                    bearbeiten | 
                    löschen</li>
                ))}
            </ul>
            </Card>}
            <p>ID: {userData.id} | UUID: {userData.user_uuid}</p>
            <p>Letzter Besuch: {formatDate(userData.last_visit)} | Besuche gesammt: {userData.visits}</p>
            <p>erstellt: {formatDate(userData.created_at)}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>



        </div>

    );
};

export default UserDetails;

