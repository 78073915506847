
export const transformToSelect = (idInput) => {
    // TODO: 
    if (idInput[0]){
        if (idInput[0].hasOwnProperty("called_name")){
            return idInput.map(type => ({
                value: type.id, 
                label: type.called_name
            }));
        } else if (idInput[0].hasOwnProperty("name")){
            return idInput.map(type => ({
                value: type.id, 
                label: type.name
            }));
        } else {
            return idInput.map(type => ({
                value: type.id, 
                label: type.id
            }));
        }
    } else {return null;}
};

export const findLabelByValue = (array, id) => {
    const element = array.find(item => item.id === id);
    if (element && element.hasOwnProperty("called_name")){
        return element.called_name;
    } else if (element && element.hasOwnProperty("name")){
        return element.name;
    } else{
        return null;
    }
};



