import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import formatDate from '../../../utility/dateUtils';
import EditableField from '../../../utility/EditableField';
import { transformToSelect, findLabelByValue} from '../../../utility/selectHelper';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as Add } from 'bootstrap-icons/icons/plus.svg';

import { useNotification } from '../../../hooks/useNotifications';

import { Card, Button, Form } from 'react-bootstrap';

const ModuleDetail = () => {
    
    const [modelData, setInputModulesData] = useState(null);
    const [inputTypes, setInputTypes] = useState(null);
    const [functionTypes, setFunctionTypes] = useState([]);
    const [parameters, setParameters] = useState([]);
    const [selectedParameters, setSelectedParameters] = useState([]);
    const [parameterToAdd, setParameterToAdd] = useState({});
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();

    
    const apiEndpoint = "/api/inputModule/";

    // Zählen, wie oft jede input_param_type_id in selectedParameters vorkommt
    const paramTypeCount = selectedParameters.reduce((acc, param) => {
        acc[param.input_param_type_id] = (acc[param.input_param_type_id] || 0) + 1;
        return acc;
    }, {});

        // Filtern Sie parameters basierend auf der Zählung und der appearances-Eigenschaft
    const filteredParameters = parameters.filter(param => {
        // Wie oft kommt diese ID in selectedParameters vor?
        const count = paramTypeCount[param.id] || 0;
        // Wenn die Anzahl der Vorkommnisse kleiner als die appearances-Anzahl ist, behalten wir das Element
        return count < param.appearances;
    });

    const handleSaveChanges = (newValue, fieldName, key = 0) => {
        const updatedModelData = { ...modelData };
    
        if (newValue === '') {
            showNotification('Feld darf nicht leer sein', 'warning');
            console.log('Feld darf nicht leer sein');
        } else {
            updatedModelData[fieldName] = newValue;
            setInputModulesData(updatedModelData);

            if (fieldName === 'input_type_id' && inputTypes) {
                fetchAvailableParameters(updatedModelData.input_type_id);
                deleteAllParameters();
            }
        }
    };


    const fetchInputTypes = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/inputType/',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setInputTypes(response.data);

        } catch (error) {
            console.error('Fehler beim Laden des Input Typen:', error);
            showNotification('Fehler beim Laden der Input Typen', 'danger');
        }
    };
    

    const fetchFuntions = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/functions/',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setFunctionTypes(response.data);
        } catch (error) {
            console.error('Fehler beim Laden des Input Typen:', error);
            showNotification('Fehler beim Laden der Input Typen', 'danger');
        }
    };

    const fetchAvailableParameters = async (datatypeid) => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/inputType/'+ datatypeid +'/inputtypes',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setParameters(response.data);
        } catch (error) {
            console.error('Fehler beim Laden der dem Inputtype möglichen zuordbaren Parametern:', error);
            showNotification('Fehler beim Laden der dem Inputtype möglichen zuordbaren Parametern', 'danger');
        }
    };

    const fetchSelectedParameters = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/inputModule/'+ id +'/inputparams',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setSelectedParameters(response.data);

        } catch (error) {
            console.error('Fehler beim Laden der dem Inputtype bereits zugeordneten Parametern:', error);
            showNotification('Fehler beim Laden der dem Inputtype bereits zugeordneten Parametern', 'danger');
        }
    };

    // Wichtig wenn der InputType wechselt -> alte Parameter haben nun keine Bedeutung mehr
    const deleteAllParameters = async () => {
        try {
            const response = await axios.delete(process.env.REACT_APP_API_BASE_URL+'/api/inputModule/'+ id +'/inputparams',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setSelectedParameters([]);
        } catch (error) {
            console.error('Fehler beim Löschen der alten Parameter:', error);
            showNotification('Fehler beim Löschen der alten Parameter', 'danger');
        }
    };


    const deleteSelectedParameters = async (paramId) => {
        try {
            const response = await axios.delete(process.env.REACT_APP_API_BASE_URL+'/api/inputModule/'+ id +'/inputparams/' + paramId,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });

            setSelectedParameters(prevParameters => 
                prevParameters.filter(parameter => parameter.id !== paramId)
            );
            console.log(selectedParameters);

        } catch (error) {
            console.error('Fehler beim Löschen des Parameters:', error);
            showNotification('Fehler beim Löschen des Parameters.', 'danger');
        }
    };

    const addSelectedParameters = async (newParam) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL+'/api/inputModule/'+ id +'/inputparams/',
            newParam,
            {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            const insertId = response.data.insertId;
            const newSelectedParameter = {id:insertId, input_module_id: id, input_param_type_id:newParam.input_param_type_id,name:newParam.name,value:newParam.value}
            setSelectedParameters(prevParameters => [...prevParameters, newSelectedParameter]);
            showNotification('parameter erfolgreich eingefügt', 'success');
        } catch (error) {
            console.error('Fehler beim Einfügen des Parameters:', error);
            showNotification('Fehler beim Einfügen des Parameters.', 'danger');
        }
    };


    useEffect(() => {
        fetchInputTypes();
        fetchFuntions();
    }, []);


    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${modelData.id}`, modelData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                showNotification('Änderungen sepeichert', 'success');                
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setInputModulesData(response.data);

            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
                console.error('Fehler beim Laden der Sprache', error);
            }
        };

        fetchData();
    }, [id]);

    useEffect(() => {
        // Funktion, um Parameter basierend auf dem ausgewählten Input-Typ zu laden
        if (modelData && modelData.input_type_id) {
            fetchAvailableParameters(modelData.input_type_id);
            fetchSelectedParameters();
        }
    }, [modelData]);

    const handleInputTypeChange = (e) => {
        setInputTypes(e.target.value);
    };

    const handleParameterChange = (paramId, value) => {
        setParameterToAdd(prevInputs => ({
            ...prevInputs,
            [paramId]: value
        }));
    };


    const adjustNameForNewParam = (newParam, parameters, selectedParameters) => {
        // Finde das entsprechende Parameterobjekt
        const paramObj = parameters.find(p => p.id === newParam.input_param_type_id);
    
        // Überprüfen, ob das Parameterobjekt existiert und 'appearances' größer als 1 ist
        if (paramObj && paramObj.appearances > 1) {
            let counter = 1;
            let modifiedName = newParam.name;
    
            // Prüfen, ob der modifizierte Name bereits existiert
            while (selectedParameters.some(sp => sp.name === modifiedName)) {
                modifiedName = `${newParam.name}_${counter}`;
                counter++;
            }
            return { ...newParam, name: modifiedName };
        }
        return newParam;
    };

    const saveParameters = async (paramid) => {
        const paramDefaults = parameters.find(element => element.id === paramid);
        const paramValue = parameterToAdd[paramid]
        const newParam ={input_module_id:parseInt(id), input_param_type_id:parseInt(paramDefaults.id), name:paramDefaults.name, value:paramValue}
        const adjustedNewParam = adjustNameForNewParam(newParam, parameters, selectedParameters);  // ändere Namen bei mehrfachem Auftreten von Parametern
        addSelectedParameters(adjustedNewParam);
    };

    if (!modelData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Spitzname:
                    <Card.Title>
                        <h2>{modelData.called_name ? <EditableField value={modelData.called_name} onSave={(newValue) => handleSaveChanges(newValue, 'called_name')} fieldType="text" /> : "...wird geladen ..."}</h2>
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Name:{modelData.name ?  <EditableField value={modelData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" /> : ""}
                    </Card.Title>
                </Card.Body>
            </Card>
            
            <Card className="my-1 shadow-sm danger">
                <Card.Body>
                    <Card.Title>
                        Beschreibung:{<EditableField value={modelData.description ? modelData.description : "noch keine Beschreibung"} onSave={(newValue) => handleSaveChanges(newValue, 'description')} fieldType="textarea" /> }
                    </Card.Title>
                </Card.Body>
            </Card>


            
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Input:
                    <EditableField 
                                value={ modelData.input_type_id ? modelData.input_type_id : ""} 
                                onSave={(newValue) => handleSaveChanges(newValue, 'input_type_id')} 
                                fieldType="dropdown" 
                                dropdownOptions={transformToSelect(inputTypes)}
                             />
                    </Card.Title>
                </Card.Body>
            </Card>
    { selectedParameters.length > 0 ?
            <Card className="my-1 shadow-sm">
                <Card.Body> zugeördnete Parameter
                    <Card.Title>
                    {selectedParameters.map(param => (
                        <div key={param.id}>
                            <span>{param.name}: </span>
                            <span>{param.value}</span> 
                            <Button onClick={() =>deleteSelectedParameters(param.id)} >
                                <Trash/>
                            </Button> 
                        </div>
                    ))}
                    </Card.Title>
                </Card.Body>
            </Card>
    : "" }
    { filteredParameters.length > 0 ?
            <Card className="my-1 shadow-sm">
                <Card.Body> mögliche Parameter
                    <Card.Title>
                    {filteredParameters.map(param => (
                        <div key={param.id}>
                            <label>{param.name} {parameters.find(element => element.id === param.id).appearances > 1 ? '('+parameters.find(element => element.id === param.id).appearances + 'x)' : '' }</label>
                            <input 
                                type="text" 
                                value={parameterToAdd[param.id] || ''} 
                                onChange={(e) => handleParameterChange(param.id, e.target.value)} 
                            />
                            
                         {parameterToAdd[param.id] && parameterToAdd[param.id].trim() != "" ? <Button onClick={() => saveParameters(param.id)}><Add/></Button>: ""}
                        </div>
                    ))}
                    </Card.Title>
                </Card.Body>
            </Card>
    :""}
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Funktion:
                    <EditableField 
                                value={ modelData.function_id ? modelData.function_id : "noch keine Funktion hinterlegt" } 
                                onSave={(newValue) => handleSaveChanges(newValue, 'function_id')} 
                                fieldType="dropdown" 
                                dropdownOptions={transformToSelect(functionTypes)}
                             /> 
                    </Card.Title>
                </Card.Body>
            </Card>


            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Platzhalter:<EditableField value={modelData.placeholder} onSave={(newValue) => handleSaveChanges(newValue, 'placeholder')} fieldType="text" /> 
                    </Card.Title>
                    Wird im Editor angezeigt z.B. #UHRZEIT
                </Card.Body>
            </Card>

            <p>Letztes Update: {modelData.updated_at ? formatDate(modelData.updated_at) : ""}</p>
            <p>erstellt: {modelData.created_at ? formatDate(modelData.created_at): ""}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>

        </div>

    );
};

export default ModuleDetail;

