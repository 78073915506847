// useAudioPlayer.js
import { useState, useRef } from 'react';

const useAudioPlayer = () => {
    const [currentAudio, setCurrentAudio] = useState(null);
    const [audioProgress, setAudioProgress] = useState(0);
    const [currentPlayingId, setCurrentPlayingId] = useState(null);
    const [currentPausedId, setCurrentPausedId] = useState(null);
    const audioPlayerRef = useRef();
    const [progressInterval, setProgressInterval] = useState(null);

    const handlePlayAudio = (audioUrl, id) => {
        //console.log(audioUrl);
        if (currentAudio === audioUrl && currentPlayingId === id) {
            // Wenn das Audio bereits läuft und es sich um das gleiche Audio handelt
            if (!audioPlayerRef.current.audio.current.paused) {
                // Audio ist im Abspielmodus, also pausieren
                audioPlayerRef.current.audio.current.pause();
                setCurrentPausedId(id);
                setCurrentPlayingId(null); // Setzen Sie die aktuelle ID auf null, wenn pausiert wird
            } else {
                // Audio ist pausiert, also abspielen
                audioPlayerRef.current.audio.current.play();
                setCurrentPlayingId(id); // Setzen Sie die aktuelle ID zurück, wenn abgespielt wird
                setCurrentPausedId(null);
            }
        } else {
            // Es ist ein neues Audio, also setzen Sie es und spielen Sie es ab
            setCurrentAudio(audioUrl);
            setCurrentPlayingId(id); // Setzen Sie die aktuelle ID für das neue Audio
    
            // Vorheriges Audio stoppen
            if (audioPlayerRef.current) {
                audioPlayerRef.current.audio.current.pause();
                setAudioProgress(0);
                setCurrentPausedId(null);
            }
    
            // Neues Audio abspielen
            setTimeout(() => {
                if (audioPlayerRef.current) {
                    audioPlayerRef.current.audio.current.play();
                }
            }, 100);
        }
    };

    const handlePlay = () => {
        // Beenden des vorherigen Intervalls, falls vorhanden
        if (progressInterval) {
            clearInterval(progressInterval);
        }
        // Intervall zum Aktualisieren des Fortschritts
        const interval = setInterval(() => {
            if (audioPlayerRef.current && audioPlayerRef.current.audio.current) {
                const progress = audioPlayerRef.current.audio.current.currentTime / audioPlayerRef.current.audio.current.duration * 100;
                setAudioProgress(progress);
            }
        }, 10); // Aktualisieren alle 100 Millisekunden

        setProgressInterval(interval);
    };

    const handlePause = () => {
        setAudioProgress(audioPlayerRef.current.audio.current.currentTime / audioPlayerRef.current.audio.current.duration * 100);
            // Beenden des Fortschrittsintervalls
        if (progressInterval) {
            clearInterval(progressInterval);
            setProgressInterval(null);
        }
    };

    const handleStop = () => {
        // Stoppt das Audio und setzt den Fortschritt auf 0
        if (audioPlayerRef.current) {
            audioPlayerRef.current.audio.current.pause();
            audioPlayerRef.current.audio.current.currentTime = 0;
            setAudioProgress(0);
        }
    
        // Setzt die Zustände für die aktuelle Wiedergabe und die Pause zurück
        setCurrentPlayingId(null);
        setCurrentPausedId(null);
    
        // Beendet das Fortschrittsintervall
        if (progressInterval) {
            clearInterval(progressInterval);
            setProgressInterval(null);
        }
    };

    const handleEnded = () => {
        setCurrentPausedId(null);
        setCurrentPlayingId(null);
        setAudioProgress(null);
            // Beenden des Fortschrittsintervalls
        if (progressInterval) {
            clearInterval(progressInterval);
            setProgressInterval(null);
        }
    };

    const handleListen = (event) => {
        const progress = (event.target.currentTime / event.target.duration) * 100;
        setAudioProgress(progress);
    };

    return {
        currentAudio,
        setCurrentAudio,
        audioProgress,
        setAudioProgress,
        currentPlayingId,
        setCurrentPlayingId,
        currentPausedId,
        setCurrentPausedId,
        setProgressInterval,
        audioPlayerRef,
        handlePlayAudio,
        handlePlay,
        handlePause,
        handleListen,
        handleEnded,
        handleStop
    };
};

export default useAudioPlayer;
