import React from 'react';
import { ReactComponent as CloseButton } from 'bootstrap-icons/icons/x.svg';
import { Button, Card } from 'react-bootstrap';
import UserStatus from '../../utility/UserStatus';

function UserDetailsOverlay({ visible, onClose }) {
  return (
    <>
      { visible ? 
        <div className="overlay">
          <div className="popup">
            <Card className="shadow-sm main-player-card pad5">
              <div style={{ position: 'absolute', top: 0, right: 0 }}>
                <CloseButton onClick={onClose} />
              </div>
              Nutzerdetails
              <Card.Body>
                <UserStatus/>
              </Card.Body>
              <Button onClick={onClose}>schließen</Button>
            </Card>
          </div>
        </div> : ''}
    </>
  );
}

export default UserDetailsOverlay;
