import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../auth/AuthContext';
import formatDate from './dateUtils';
import { Dropdown } from 'react-bootstrap';

const StoryLines = ({ processId, fetchRating = false }) => {
  const { storyLine, user, currStoryLine} = useContext(AuthContext);
  const token = localStorage.getItem('token');
  const [storyLines, setStoryLines] = useState([]);

  const fetchStoryLines = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_API_BASE_URL + '/api/stories/storylines/', {
        headers: {
          'Authorization': `Bearer ${token}`, 
        },
      });

      if (response.status === 200 || response.status === 201) {
        setStoryLines(response.data);
        currStoryLine(response.data[0].storyline);  //die aktuellste
      } else {
        console.error('Fehler beim Abrufen der bisherigen Stories.');
      }
    } catch (error) {
      console.error('Fehler beim Laden der bisherigen Stories:', error);
    }
  };

  useEffect(() => {
    if (user){
    fetchStoryLines();}
  }, [user]);

  const handleSetCurrentStoryline = (storyLine) => {
    currStoryLine(storyLine);
  };
  

  return (
    (storyLines.length > 1) && user ? ( // Ändern Sie > 1 zu > 0, wenn Sie möchten, dass das Dropdown für 1 oder mehr Storylines erscheint
      <Dropdown>
        
        <Dropdown.Toggle 
            id="dropdown-basic"
            style={{color: 'var(--sd-08)'}}
        >
          Storylines
        </Dropdown.Toggle>
  
        <Dropdown.Menu
                      style={{
                        background: 'var(--sd-06)',
                    }}
            >
          {storyLines.map((line, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleSetCurrentStoryline(line.storyline)}
              style={{

                background: (line.storyline === storyLine ? 'var(--sd-07)' : ''),
                color: 'var(--sd-08)'


            }}
            >
              {line.name + ' ' + formatDate(line.created_at.trim(), 'date-time')}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    ) : null
  );
};


export default StoryLines;
