import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../utility/dateUtils';
import SortIndicator from '../../utility/SortIndicator';
import { useNotification } from '../../hooks/useNotifications';
import { ReactComponent as CurrencyEuro } from 'bootstrap-icons/icons/currency-euro.svg';


const FeedbackTab = () => {
    const [feedback, setFeedback] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
   

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    const sortedFeedback = [...feedback].sort((a, b) => {
        // Erkennen, ob der Schlüssel für eine numerische Sortierung steht
        const isNumericSort = ['prompt_id','user_id', 'process_id'].includes(sortConfig.key);
        const isDateSort = ['created_at'].includes(sortConfig.key);
    
        if (isDateSort) { // Datumssortierung
            const dateA = new Date(a[sortConfig.key] ?? "1970-01-01");
            const dateB = new Date(b[sortConfig.key] ?? "1970-01-01");
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (isNumericSort) { // Numerische Sortierung
            const numA = parseFloat(a[sortConfig.key] ?? "0");
            const numB = parseFloat(b[sortConfig.key] ?? "0");
            return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
        } else { // Alphabetische Sortierung für andere Felder
            const valueA = a[sortConfig.key] ?? "0";
            const valueB = b[sortConfig.key] ?? "0";
            return sortConfig.direction === 'asc' ? 
                valueA.localeCompare(valueB) : 
                valueB.localeCompare(valueA);
        }
    });


    const deleteFeedback = async (id) => { // noch nicht implementiert
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchFeedback();
                showNotification('Modell erfolgreich gelöscht', 'success');
            } else {
                console.error('Fehler beim löschen des Feedbacks');
                showNotification('Fehler beim Senden der Anfrage', 'danger');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
            showNotification('Fehler beim Senden der Anfrage', 'danger');
        }
    };

    const fetchFeedback = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setFeedback(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden des Feedback:', error);
            showNotification('Fehler beim Laden des Feedback', 'danger');
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchFeedback();
    }, []);


    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>Feedback</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('feedback_text')}>
                        feedback_text
                            <SortIndicator isSorted={sortKey === 'feedback_text'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('contact')}>
                        contact
                            <SortIndicator isSorted={sortKey === 'contact'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('process_id')}>
                        process_id
                            <SortIndicator isSorted={sortKey === 'process_id'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('prompt_id')}>
                        prompt_id
                            <SortIndicator isSorted={sortKey === 'prompt_id'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('user_id')}>
                        user_id
                            <SortIndicator isSorted={sortKey === 'user_id'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedFeedback.map(fb => (
                        <tr key={fb.id}>
                            <td>{fb.feedback_text.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>
                            <td>{fb.contact ? fb.contact.trim().replace(/(\r\n|\n|\r)/gm, ""): '--'}</td>                           
                            <td>{fb.process_id}</td>
                            <td>{fb.prompt_id}</td>
                            <td>{fb.user_id}</td>
                            <td>{formatDate(fb.created_at.trim())}</td>
                            <td><Link to={`./${fb.id}`}>Details</Link></td>
                            <td><button onClick={() => deleteFeedback(fb.id)}>-</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>


   
    </div>

    );


};

export default FeedbackTab;