import React, { useContext } from 'react';

import LogoutButton from '../common/Logout';
import RenewToken from './RenewToken';
import { AuthContext }from '../auth/AuthContext';
import TokenCountdown from './TokenCountdown';

const UserStatus = () => {
    const { user } = useContext(AuthContext);

    return (
        <div>
            {user ? (
                <div className='text-left'>

                <p>Eingeloggt als "{user.username}"</p>
                <p>UUID : {user.userid}</p>
                <p>Status: {user.userrole}</p>
                <TokenCountdown/>
                <LogoutButton /> <RenewToken />
                </ div>
            ) : (
                <p>Nicht eingeloggt</p>
            )}
        </div>
    );
};

export default UserStatus;