import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Modal, Button, Spinner, ProgressBar } from 'react-bootstrap';
import axios from 'axios';

import { useNotification } from '../hooks/useNotifications';

const FileDropzone = ({uploadroute, context, fileType, maxFiles=1, maxSize=1 * 1024 * 1024, onUploadSuccess }) => {
    const { showNotification } = useNotification();
    const [modalShow, setModalShow] = useState(false);
    const [modalInactive, setModalInactive] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const isValidFile = (file) => {
        const maxSizeInBytes = maxSize * 1024 * 1024; 
        let acceptedFileTypes =[];
        if (fileType ==='audio'){
            acceptedFileTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg'];
        } else if (fileType ==='image'){
            acceptedFileTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp'];
        } // Erweiterbar mit weiteren Typen
        return file.size <= maxSizeInBytes && acceptedFileTypes.includes(file.type);
    };

    const onDrop = useCallback(acceptedFiles => {
        // Filtern der gültigen Dateien

        
        const validFiles = acceptedFiles.filter(isValidFile);
    
        if (validFiles.length === 0) {
            // Fehlermeldung anzeigen oder anderweitig reagieren
            showNotification('Ungültige Datei. Bitte wählen Sie eine Datei unter '+maxSize+' MB.', 'danger');
            return;
        }
    
        setModalInactive(true);
        // Erstellen eines FormData-Objekts
        const formData = new FormData();
        formData.append('context', context);
        formData.append('file', validFiles[0]); // 'file' ist der Schlüssel, auf den das Backend zugreifen wird
        // Senden der Anfrage
        axios.post(uploadroute, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: progressEvent => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted); // Fortschritt aktualisieren
            }
        }).then(response => {
            onUploadSuccess(response.data);
            showNotification('Datei erfolgreich hochgeladen', 'success');
            //console.log('Datei erfolgreich hochgeladen', response.data);
            setModalShow(false);
            setModalInactive(false);
        }).catch(error => {
            showNotification('Fehler beim Hochladen der Datei', 'danger')
            console.error('Fehler beim Hochladen der Datei', error);
        });

    }, []);

    let acceptFileEndings ={};
    if (fileType === 'audio' ){
        acceptFileEndings = {'audio/*': ['.mp3', '.wav', '.ogg']};
    } else if (fileType === 'image' ){
        acceptFileEndings = {
            'image/jpeg': ['.jpeg', '.jpg'],
            'image/png': ['.png'],
            'image/gif': ['.gif'],
            'image/bmp': ['.bmp']};
    }

    const { getRootProps, getInputProps} = useDropzone({
        onDrop,
        accept: acceptFileEndings,
        maxSize: maxSize* 1024 * 1024,
        maxFiles:maxFiles
    });

    return (
        <>
            <Button variant="primary" onClick={() => setModalShow(true)}>
                Datei hochladen
            </Button>

            <Modal
                show={modalShow}
                onHide={() => setModalShow(false)}
                size="lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Datei hochladen</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div {...getRootProps()} style={{ border: '2px dashed #007bff', padding: '20px', textAlign: 'center' }}>
                        <input {...getInputProps()} />
                        <p>Ziehen Sie Dateien hierher oder klicken Sie zum Auswählen</p>
                        {modalInactive && (
                            <div style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.8)'
                            }}>
                                <Spinner animation="border" variant="primary" />
                                <div className="upload-progressbar">
                                    <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
                                </div>
                                
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setModalShow(false)}>Schließen</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FileDropzone;
