import React, {} from 'react';
import {Card} from 'react-bootstrap';

const StartCard = () => {

  return (
  <div>
    
  <Card className="shadow-sm main-player-card pad5">
  Schön, dass du da bist!
      <Card.Body>
      Story2go ermöglicht es dir, mit einer Geschichte zu interagieren. Indem du kleine Aufgaben löst oder Entscheidungen triffst, kannst du Einfluss auf das Geschehen nehmen. 
        {/* Weitere Inhalte der Karte */}
      </Card.Body>
    </Card>
  </div>
  );

};

export default StartCard;
