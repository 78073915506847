import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../utility/dateUtils';
import SortIndicator from '../../utility/SortIndicator';
import { useNotification } from '../../hooks/useNotifications';


const PromoCodesTab = () => {
    const [promoCodes, setPromoCodes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
   

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    const sortedCodes = [...promoCodes].sort((a, b) => {
        // Erkennen, ob der Schlüssel für eine numerische Sortierung steht
        const isNumericSort = ['amount_max', 'amount_used'].includes(sortConfig.key);
        const isDateSort = ['created_at', 'valid_from', 'valid_till'].includes(sortConfig.key);
    
        if (isDateSort) { // Datumssortierung
            const dateA = new Date(a[sortConfig.key]);
            const dateB = new Date(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (isNumericSort) { // Numerische Sortierung
            const numA = parseFloat(a[sortConfig.key]);
            const numB = parseFloat(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
        } else { // Alphabetische Sortierung für andere Felder
            return (
                sortConfig.direction === 'asc' ? 
                a[sortConfig.key].localeCompare(b[sortConfig.key]) : 
                b[sortConfig.key].localeCompare(a[sortConfig.key])
            );
        }
    });

    const addPromoCode = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/promocodes/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchPromoCodes();
                showNotification('leerer Prpmp Code erfolgreich erstellt', 'success');
            } else {
                console.error('Fehler beim Erstellen des leeren Promo Codes');
                showNotification('Fehler beim Erstellen des leeren Promo Codes', 'danger');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const deletePromoCode = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/promocodes/${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchPromoCodes();
                showNotification('Promo Code erfolgreich gelöscht', 'success');
            } else {
                console.error('Fehler beim löschen des Promo Codes');
                showNotification('Fehler beim Senden der Anfrage', 'danger');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
            showNotification('Fehler beim Senden der Anfrage', 'danger');
        }
    };

    const fetchPromoCodes = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/promocodes/`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setPromoCodes(response.data);

            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden des Modelle:', error);
            showNotification('Fehler beim Laden des Modelle', 'danger');
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchPromoCodes();
    }, []);


    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>Promo Codes</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('name')}>
                        Name
                            <SortIndicator isSorted={sortKey === 'name'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('code')}>
                        Code
                            <SortIndicator isSorted={sortKey === 'code'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('amount_max')}>
                        Max Nutzungen
                            <SortIndicator isSorted={sortKey === 'amount_max'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('amount_used')}>
                        Nutzungen
                            <SortIndicator isSorted={sortKey === 'amount_used'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('valid_from')}>
                        Gültig ab
                            <SortIndicator isSorted={sortKey === 'valid_from'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('valid_till')}>
                        Gültig bis
                            <SortIndicator isSorted={sortKey === 'valid_till'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                    </tr>
                </thead>
                <tbody>
                {sortedCodes.map(promoCode => (
                    <tr key={promoCode.id}>
                        <td style={{ backgroundColor: 
                            (promoCode.amount_used < promoCode.amount_max)&&(new Date() < new Date(promoCode.valid_till)) && (new Date() > new Date(promoCode.valid_from))  ? 'green' : 'inherit' }}>
                            {promoCode.name.trim().replace(/(\r\n|\n|\r)/gm, "")}
                        </td>
                        <td>{promoCode.code.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>                           
                        <td>{promoCode.amount_max}</td>
                        <td style={{ backgroundColor: 
                            (promoCode.amount_max <= promoCode.amount_used)  ? 'red' : 'inherit' }}>{promoCode.amount_used}</td>
                        <td style={{ backgroundColor: new Date() < new Date(promoCode.valid_from) ? 'red' : 'inherit' }}>
                            {formatDate(promoCode.valid_from.trim())}</td>
                        <td style={{ backgroundColor: new Date() > new Date(promoCode.valid_till) ? 'red' : 'inherit' }}>
                        {formatDate(promoCode.valid_till.trim())}</td>
                        <td>{formatDate(promoCode.created_at.trim())}</td>
                        <td><Link to={`./${promoCode.id}`}>Details</Link></td>
                        <td><button onClick={() => deletePromoCode(promoCode.id)}>-</button></td>
                    </tr>
                ))}
                </tbody>
            </table>
            <button onClick={addPromoCode}>
            neuen (leeren) Promocode erstellen
        </button>
    </div>
    );
};

export default PromoCodesTab;