import React, { useState, useEffect } from 'react';
import {Link } from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../../utility/dateUtils';
import SortIndicator from '../../../utility/SortIndicator';

const FunctionsTab = () => {
    const [functions, setFunctions] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const token = localStorage.getItem('token');

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    
    const sortedFunctions = [...functions].sort((a, b) => {
        if (sortConfig.key === 'created_at') { // Datumssortierung
            const dateA = new Date(a[sortConfig.key]);
            const dateB = new Date(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else { // Alphabetische Sortierung für andere Felder
            return (
                sortConfig.direction === 'asc' ? 
                a[sortConfig.key].localeCompare(b[sortConfig.key]) : 
                b[sortConfig.key].localeCompare(a[sortConfig.key])
            );
        }
    });

    const addFunction = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/functions/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                fetchFunctions();
            } else {
                console.error('Fehler beim Erstellen der leeren Funltion Daten');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const deleteFunction = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/functions/${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                console.log(response);
                fetchFunctions();
            } else {
                console.error('Fehler beim Erstellen der leeren Funltion Daten');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const fetchFunctions = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/functions/`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            //console.log(response.data);
            setFunctions(response.data);

            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden der Funktionen:', error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchFunctions();
    }, []);

    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>Verfügbare Funktionen</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('name')}>
                            Name
                            <SortIndicator isSorted={sortKey === 'name'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('description')}>
                            Beschreibung
                            <SortIndicator isSorted={sortKey === 'description'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                        {/* Weitere Spalten nach Bedarf */}
                    </tr>
                </thead>
                <tbody>
                    {sortedFunctions.map(func => (
                        <tr key={func.id}>
                            <td>{func.name.trim()}</td>
                            <td>{func.description.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>
                            <td>{formatDate(func.updated_at.trim())}</td>
                            <td><Link to={`./${func.id}`}>Details</Link></td>
                            <td><button onClick={() => deleteFunction(func.id)}>-</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <button onClick={addFunction}>
            leere Funktion erstellen
        </button>
        </div>
    );
};

export default FunctionsTab;