import React, { useState, useEffect, useCallback, useRef, Suspense, useContext } from 'react';

import { Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { AuthContext } from '../auth/AuthContext';
import ProgressOverlay from './overlays/ProgressOverlay';
import { useNotification } from '../hooks/useNotifications';
  

function MainInputs({ userUUID, lastProcessId, onGotServerResponse }) {
  const token = localStorage.getItem('token');
  const [inputComponents, setInputComponents] = useState([]);
  const [thisProcessId, setThisProcessUUID] = useState([]);
  const inputDataRef = useRef({});
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [newCardData, setNewCardData] = useState({});
  const [isLastStory, setIsLastStory] = useState(false);
  const [inputsLength, setInputsLength] = useState(0);
  const [settedInputsLength, setSettedInputsLength] = useState(0);
  const {showNotification} = useNotification();
  const { storyLine } = useContext(AuthContext);

  const loadComponent = async (componentType) => {
    switch (componentType.type) {
      case 'InputSlider':
        return (await import('../input/InputSlider')).default;
      case 'InputPhoto':
        return (await import('../input/InputPhoto')).default;
      case 'InputSquares':
        return (await import('../input/InputButtons')).default;
      case 'InputTemp':
        return (await import('../input/InputTemp')).default;
      default:
        return null;
    }
  };


  const fetchComponents = async () => {
    try {
      
      const response = await axios.post(process.env.REACT_APP_API_BASE_URL + '/getInputs/', { userUUID, lastProcessId }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.data && 'status' in response.data && 'next' in response.data && response.data.next ==='none')  {
        setIsLastStory(true);
        setNewCardData({
            status : 'success',
            processId : 'end',
            cardStatus : -2
          });
        return;
      }
      const components = response.data.inputComponents;
      //console.log('Anzagl der Input Komponenten: ' + components.length);
      setInputsLength(components.length);
      const loadedComponents = await Promise.all(components.map(async (component) => {
        const LoadedComponent = await loadComponent(component);
        return LoadedComponent ? <LoadedComponent key={component.id} {...component.props} id={component.id} onAction={(datatype, value) => handleComponentChange(component.id, component.type, datatype, value)} /> : null;
      }));
      setInputComponents(loadedComponents);
      setThisProcessUUID(response.data.processUUID);
    } catch (error) {
      console.error('Fehler beim Laden der Komponenten:', error);
    }
  };

  useEffect(() => {
    fetchComponents();
  }, []);


const handleSendInputs = () => {

  if (!isLastStory){
  const formData = new FormData();
  formData.append('processId', thisProcessId); // Die ProzessId an erster Stelle
  formData.append('storyLine', storyLine); 
  //formData.append('userUUID', userUUID); // Die ProzessId an erster Stelle
  for (const key in inputDataRef.current) {
      formData.append(key, inputDataRef.current[key].value);
  
    }
  sendResponse(formData);}
};

const sendResponse = async (formData) => {
  setOverlayVisible(true);
  try {
    const response = await axios.post(process.env.REACT_APP_API_BASE_URL+'/sendInputs/', formData, {
      headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
      }
    });
    setNewCardData(response.data);
    //showNotification('All Good!', 'success');
  } catch (error) {
    console.error('Fehler beim Senden:', error);
    showNotification('Fehler beim Senden der Komponenten', 'danger');
    setNewCardData({ status: 'error', message: 'Fehler beim Senden der Komponenten' });
  } finally {
    setOverlayVisible(false);
  }
};


useEffect(() => {
  if (newCardData && newCardData.length > 0 && onGotServerResponse ) {
      onGotServerResponse(newCardData); // Callback aufrufen
      //console.log('call Callback mit ');
      //console.log(newCardData);
  }
}, [newCardData, onGotServerResponse]);


const handleInputChange = (id, type, value) => {
  inputDataRef.current = { ...inputDataRef.current, [id]: { type: type, value: value } };
  setSettedInputsLength(Object.keys(inputDataRef.current).length);
};


const handleComponentChange = useCallback((id, type, datatype, value) => {
    if (type === 'InputSlider') {
      //console.log(`Slider mit id ${id} hat folgenden Wert:`+value);
    } else if (type === 'InputPhoto') {
      //console.log(`Foto von Komponente ${id} aufgenommen`);
    }
    handleInputChange(id, datatype, value);
  }, []);

  
  return (

    <div>
      {(lastProcessId !== 'end' || !isLastStory) && thisProcessId &&
        <>
        <Card className="mt1 shadow-sm main-player-card">
          <Suspense fallback={<div>Laden...</div>}>
            {inputComponents.slice(0, settedInputsLength + 1)}
          </Suspense>

            <Card.Body>
              
            {settedInputsLength === inputsLength ? <Button variant="primary" onClick={handleSendInputs} className="w-100">
              Abschicken
            </Button>
                : 
                <>

          <button className="btn-deactivated w-100">Abschicken</button>
          </>  }
            </Card.Body>
          </Card>
          <ProgressOverlay visible={overlayVisible} />
        </>}
    </div>
  );
}


export default MainInputs;