import React, { useState } from 'react';
import { ReactComponent as PencilIcon } from 'bootstrap-icons/icons/pencil.svg';
import { ReactComponent as SaveIcon } from 'bootstrap-icons/icons/save.svg';
import { useNotification } from '../hooks/useNotifications';



const EditableField = ({ value, onSave, fieldType, dropdownOptions,minValue, maxValue, stepSize=1, className, modules=[], colorMapping={} }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editableValue, setEditableValue] = useState(value);
    const { showNotification } = useNotification();


    const handleSave = () => {
        onSave(editableValue);
        setIsEditing(false);
    };

    const determineDecimalPlaces = (stepSize) => {
        const decimalPart = stepSize.toString().split('.')[1];
        return decimalPart ? decimalPart.length : 0;
    };

    const highlightText = (text, modules, colorMapping) => {
        if (colorMapping.length === 0 || modules.length === 0){
            return text;
        }
        // Teile den Text an den Stellen, wo Module vorkommen
        const regex = new RegExp(`(${modules.map(m => m.placeholder).join('|')})`, 'g');
        return text.split(regex).map((part, index) => {
            const module = modules.find(m => m.placeholder === part);
            const style = module ? { backgroundColor: colorMapping[module.placeholder] } : {};
            return <span key={index} style={style}>{part}</span>;
        });
    };

    // zeige bei dropdown das label und nicht den value an
    const getDropdownLabel = (value) => {
        let option;
        if (dropdownOptions)
            option = dropdownOptions.find(option => option.value.toString() === value.toString());
        return option ? option.label : 'Unbekannt';
    };


    const renderInput = () => {
        switch (fieldType) {
            case 'text':
                return <input type="text" value={editableValue} onChange={(e) => setEditableValue(e.target.value)} />;
            case 'textarea':
                return <textarea className={className} value={editableValue} onChange={(e) => setEditableValue(e.target.value)}></textarea>;
            case 'dropdown':
                return (
                    <select value={editableValue} onChange={(e) => setEditableValue(e.target.value)}>
                        {dropdownOptions.map(option => (
                            <option key={option.value} value={option.value} >{option.label}</option>
                        ))}
                    </select>
                );
            case 'number':
                return <input type="number" min={minValue} max={maxValue} value={editableValue} 
                onChange={(e) => {
                    let value = parseInt(e.target.value, 10);
                    if (isNaN(value)) {
                        value = '';
                    } else if (value > maxValue) {
                        value = maxValue;
                        showNotification('Wert muss kleiner als ' + maxValue + ' sein.', 'warning');
                    } else if (value < minValue) {
                        value = minValue;
                        showNotification('Wert muss größer als ' + minValue + ' sein.', 'warning');
                    }
                    setEditableValue(value);
                }} />;
                
            case 'range':
                return (
                    <span>
                        <span style={{ marginRight: '1rem'}}>
                            {editableValue.toFixed(determineDecimalPlaces(stepSize))}
                        </span>
                        <input 
                            type="range" 
                            min={minValue} 
                            max={maxValue} 
                            step={stepSize} 
                            value={editableValue} 
                            onChange={(e) => setEditableValue(parseFloat(e.target.value))}
                        />
                    </span>
                );

                case 'date':
                    return (
                    <span>
                        <input 
                            type="date" 
                            value={editableValue} 
                            min={minValue} 
                            max={maxValue} 
                            onChange={(e) => setEditableValue(e.target.value)} 
                        />
                    </span>
                    );

            default:
                return null;
        }
        
    };

    return (
        <span className="editable-field">
            {isEditing ? (
                <>
                    {renderInput()}
                    <SaveIcon className='editicon' onClick={handleSave} />
                </>
            ) : (
                <>
                    {fieldType === 'dropdown' 
                        ? getDropdownLabel(value) 
                        : highlightText(value, modules, colorMapping)}
                    <PencilIcon className='editicon' onClick={() => setIsEditing(true)} />
                </>
            )}
        </span>
    );
};


export default EditableField;