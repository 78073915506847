import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import formatDate from '../../../utility/dateUtils';
import EditableField from '../../../utility/EditableField';
import { transformToSelect, findLabelByValue} from '../../../utility/selectHelper';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as Add } from 'bootstrap-icons/icons/plus.svg';
import { useNotification } from '../../../hooks/useNotifications';
import { Card, Button, Form } from 'react-bootstrap';
import FileDropzone from '../../../utility/FileDropzone';

const PromptDetail = () => {
    
    const [promptData, setPromptData] = useState(null);
    const [models, setModels] = useState([]);
    const [voices, setVoices] = useState([]);
    const [modules, setModules] = useState([]);
    const [promptModules, setPromptModules] = useState([]);
    const [selectedModuleId, setSelectedModuleId] = useState("");
    // Filtern von Modulen, die zum aktuellen Prompt gehören
    const [filteredModules, setFilteredModules] = useState([]);
    // Filtern von Modulen, die nicht zum aktuellen Prompt gehören
    const [nonReferencedModules, setNonReferencedModules] = useState([]);


    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();
    const apiEndpoint = "/api/prompts/";

    // Funktion zum Aktualisieren der gefilterten Module
    const updateFilteredModules = useCallback(() => {
        const filtered = modules.filter(module => 
            promptModules.some(promptModule => promptModule.input_module_id === module.id)
        );
        setFilteredModules(filtered);
        const nonReferenced = modules.filter(module => 
            !promptModules.some(promptModule => promptModule.input_module_id === module.id)
        );
        setNonReferencedModules(nonReferenced);
    }, [modules, promptModules]);

    // useEffect, der auf Änderungen in promptModules und modules reagiert
    useEffect(() => {
        updateFilteredModules();
    }, [promptModules, modules, updateFilteredModules]);


    const handleSaveChanges = (newValue, fieldName, key=0) => {
        const updatedModelData = { ...promptData };
        if (newValue === ''){
            showNotification('Feld darf nicht leer sein', 'warning');
            console.log('Feld darf nicht leer sein');
        }
        else {
            updatedModelData[fieldName] = newValue;
       }
        setPromptData(updatedModelData);
    };

    const handleAddModule = async (moduleid) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}/modules/${moduleid}`, {}, {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });

            setPromptModules(prevModules => {
                const newModules = [...prevModules];
                const moduleToAdd = { prompt_id: parseInt(id), input_module_id: parseInt(moduleid)};
                if (moduleToAdd) {
                    newModules.push(moduleToAdd);
                }
                return newModules;
            });

            updateFilteredModules();
            showNotification('Modul erfolgreich hinzugefügt', 'success');
    
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Moduls:', error);
            showNotification('Fehler beim Hinzufügen des Moduls', 'danger');
        }
    };

    const handleDeleteModule = async (moduleid) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`+'/modules/'+moduleid,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            // Modul aus promptModules entfernen
            setPromptModules(prevModules => prevModules.filter(module => module.input_module_id !== parseInt(moduleid)));
            updateFilteredModules();

            showNotification('Modul erfolgreich entfernt', 'success');
        } catch (error) {
            console.error('Fehler beim Laden des Modelle:', error);
            showNotification('Fehler beim Hinzufügen des Moduls', 'danger');
        }
    };

    const fetchPromptModels = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`+'/modules',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setPromptModules(response.data);
        } catch (error) {
            console.error('Fehler beim Laden des Modelle:', error);
            showNotification('Fehler beim Laden der Modelle', 'danger');
        }
    };

    const fetchModels = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/models/',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setModels(response.data);
        } catch (error) {
            console.error('Fehler beim Laden des Modelle:', error);
            showNotification('Fehler beim Laden der Modelle', 'danger');
        }
    };
    

    const fetchVoices = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/voices/',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setVoices(response.data);
        } catch (error) {
            console.error('Fehler beim Laden der Stimmen:', error);
            showNotification('Fehler beim Laden der Stimmen', 'danger');
        }
    };

    const fetchModules = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/inputModule',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setModules(response.data);
        } catch (error) {
            console.error('Fehler beim Laden der Module:', error);
            showNotification('Fehler beim Laden der Module', 'danger');
        }
    };

    useEffect(() => {
        fetchModels();
        fetchVoices();
        fetchModules();
        fetchPromptModels();
    }, []); 

    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${promptData.id}`, promptData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                showNotification('Änderungen sepeichert', 'success');                
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setPromptData(response.data[0]);
                console.log(response.data[0]);
            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
                console.error('Fehler beim Laden der Prompts', error);
            }
        };

        fetchData();
    }, [id]);

    const handleCoverFileUploadSuccess = (uploadedData) => {
        //setPromptData({ ...promptData, cover: uploadedData.filename });
        setPromptData({ ...promptData, cover: uploadedData.filename , cover_url: uploadedData.fileurl });

      };

    const handleCoverDelete = async (fileName, filepath, id) => {
        try {
            const response = await axios.delete(filepath + fileName, { 
                data: { id: id }
            });
            if (response.status===201){
                setPromptData({ ...promptData, cover: null, cover_url: null });
                showNotification('Coverdatei erfolgreich gelöscht.', 'success')
            } else {
                showNotification('Fehler beim Löschen der Datei.', 'danger')
            }
        } catch (error) {
            console.error('Fehler beim Löschen der Datei:', error);
            showNotification('Serverfehler beim Löschen der Datei.', 'danger')
        }
    };

    // Farbcodierung für Platzhalter
    const colorMapping = {};

    filteredModules.forEach((module, index) => {
        // Erstellen Sie ein einfaches Farbschema oder verwenden Sie eine Farbbibliothek
        const color = `hsl(${index * 40 % 360}, 70%, 60%)`;
        colorMapping[module.placeholder] = color;
    });

    if (!promptData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Spitzname:
                    <Card.Title>
                        <h2><EditableField value={promptData.called_name} onSave={(newValue) => handleSaveChanges(newValue, 'called_name')} fieldType="text" /></h2>
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Name:<EditableField value={promptData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Überschrift:<EditableField value={promptData.title} onSave={(newValue) => handleSaveChanges(newValue, 'title')} fieldType="text" />
                    </Card.Title>
                </Card.Body>
            </Card>
            
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Beschreibung: <EditableField value={promptData.description ? promptData.description : ""} onSave={(newValue) => handleSaveChanges(newValue, 'description')} fieldType="textarea" /> 
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Text: <EditableField value={promptData.promt_text ? promptData.promt_text : ""} onSave={(newValue) => handleSaveChanges(newValue, 'promt_text')} fieldType="textarea" modules={filteredModules} colorMapping={colorMapping} /> 
                    </Card.Title>
                        {filteredModules.map(module => (
                        <span key={module.id} style={{ backgroundColor: colorMapping[module.placeholder] }}>
                            {module.placeholder}
                        </span>))}
                </Card.Body>
            </Card>


            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Module:
                    </Card.Title>
                    {filteredModules.map(module => (
                        <Card key={module.id}>
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Card.Title>
                                        {module.called_name ? module.called_name : ""}
                                    </Card.Title>
                                    <Button onClick={() => handleDeleteModule(module.id)} >
                                        <Trash/>
                                    </Button> 
                                </div>
                                Variablen: {module.placeholder ? module.placeholder : ""}
                            </Card.Body>
                        </Card>
                    ))}

                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Neues Modul:
                                    {nonReferencedModules ? <select onChange={(e) => setSelectedModuleId(e.target.value)} value={selectedModuleId}>
                                        <option value="">--bitte auswählen--</option>
                                        {nonReferencedModules.map(option => (
                                            <option key={option.id} value={option.id}>{option.called_name}</option>
                                        ))} 
                                    </select>  : ""}  
                                    {selectedModuleId==="" ? "" : <Button onClick={() => handleAddModule(selectedModuleId)} ><Add/></Button>}
                                

                                </Card.Title>
                            </Card.Body>
                        </Card>
                </Card.Body>
            </Card> 

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Cover: 
                        {promptData.cover ? (
                            <>
                                <img 
                                    src={promptData.cover_url} 
                                    alt="Cover" 
                                    style={{ width: '100px', height: 'auto' }} // Größe des Bildes anpassen
                                />
                                <Button onClick={() => handleCoverDelete(promptData.cover, process.env.REACT_APP_API_BASE_URL +'/api/prompts/cover/', id)} >
                                    <Trash/>
                                </Button>
                            </>
                        ) : ''}
                        <FileDropzone 
                            uploadroute={process.env.REACT_APP_API_BASE_URL +'/api/prompts/cover'}
                            context={JSON.stringify({id:id})}
                            fileType={'image'}
                            maxFiles={1}
                            maxSize={10} // in 10 MB
                            onUploadSuccess={handleCoverFileUploadSuccess}
                        />
                        <Form.Switch
                            id="use_cover_image"
                            label="Nutze User Uploads (falls vorhanden)"
                            checked={promptData.use_user_cover === 1}
                            onChange={(e) => handleSaveChanges(e.target.checked ? 1 : 0, 'use_user_cover')}
                            // Hier können Sie weitere Props wie onChange hinzufügen
                        />
                    </Card.Title>
                </Card.Body>
            </Card>


            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Gruppe:<EditableField value={promptData.promtgroup ? promptData.promtgroup: ""} onSave={(newValue) => handleSaveChanges(newValue, 'promtgroup')} fieldType="text" />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Vorherige Prompt ID (wird in Zukubft besser):<EditableField value={promptData.prev_prompt ? promptData.prev_prompt : -1} onSave={(newValue) => handleSaveChanges(newValue, 'prev_prompt')} fieldType="number" />
                    </Card.Title>
                    -1 für Start
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Nächste Prompt ID (wird in Zukubft besser):<EditableField value={promptData.next_prompt ? promptData.next_prompt : -1} onSave={(newValue) => handleSaveChanges(newValue, 'next_prompt')} fieldType="number" />
                    </Card.Title>
                    -1 für Ende
                </Card.Body>
            </Card>
            
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Verwende letzte x Nachrichten:<EditableField value={promptData.include_last_prompts ? promptData.include_last_prompts : 0} onSave={(newValue) => handleSaveChanges(newValue, 'include_last_prompts')} fieldType="number" />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Modell: 
                    <EditableField 
                                value={ promptData.language_model_id ? promptData.language_model_id : ""}
                                label={ promptData.called_name} 
                                onSave={(newValue) => handleSaveChanges(newValue, 'language_model_id')} 
                                fieldType="dropdown" 
                                dropdownOptions={transformToSelect(models)}
                             />
                    </Card.Title>
                </Card.Body>
            </Card>
            

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Stimme:
                    <EditableField 
                                value={ promptData.voice_id ?  promptData.voice_id : ""} 
                                onSave={(newValue) => handleSaveChanges(newValue, 'voice_id')} 
                                fieldType="dropdown" 
                                dropdownOptions={transformToSelect(voices)}
                             />
                    </Card.Title>
                </Card.Body>
            </Card>


            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        status:<EditableField value={promptData.status ? promptData.status : ""} onSave={(newValue) => handleSaveChanges(newValue, 'status')} fieldType="number" />
                    </Card.Title>
                    TODO: Satus austomatisch ermitteln
                </Card.Body>
            </Card>

            <p>Letztes Update: {promptData.updated_at ? formatDate(promptData.updated_at) : ""}</p>
            <p>erstellt: {promptData.created_at ? formatDate(promptData.created_at): ""}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>

        </div>

    );
};

export default PromptDetail;

