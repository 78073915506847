import React, { createContext, useState, useEffect, useContext } from 'react';

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children, url }) => {
  const [progress, setProgress] = useState(0);
  const [description, setDescription] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const ws = new WebSocket(url);

    ws.onopen = () => {
      setVisible(true);
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.clearMessage) {
        // Setzen Sie den Fortschritt und die Beschreibung zurück oder aktualisieren Sie sie
        setProgress(0);
        setDescription('');
      } else {
        // Aktualisieren Sie den Fortschritt und die Beschreibung normal
        setProgress(data.progress);
        setDescription(data.description);
      }
    };

    ws.onclose = () => {
      setVisible(false);
    };

    return () => {
      ws.close();
    };
  }, [url]);

  return (
    <WebSocketContext.Provider value={{ progress, description, visible }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocketContext = () => useContext(WebSocketContext);