import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../components/auth/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { useNotification } from '../components/hooks/useNotifications';

function Code() {
    const [code, setCode] = useState('');
    const { login, coockies } = useContext(AuthContext);
    const navigate = useNavigate();
    const {  activationCode } = useParams(); // Extrahieren des Codes aus der URL
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();

    useEffect(() => {
        // Wenn ein Freischaltcode in der URL vorhanden ist, führen Sie die Aktion direkt aus
        if (activationCode && (!token) && coockies) {

            submitCode(activationCode);
        }
    }, [activationCode, coockies]);

    useEffect(() => {
        // Wenn user schon einen Token hat direkt zur Statseite weiterleiten
        if ((token)) {
            navigate('/');
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await submitCode(code);
    };

    const submitCode = async (codeToSubmit) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/code/${codeToSubmit}`);
            const token = response.data.accessToken;
            const decodedToken = jwtDecode(token);
            localStorage.setItem('token', token);
            const expiryTime = decodedToken.exp;
            //login({ username: decodedToken.username }, expiryTime); // Aktualisieren des AuthContext-Zustands, Username je nach Token-Struktur
            login({ username: decodedToken.username, userid: decodedToken.userId, userrole: decodedToken.role}, expiryTime);
            //console.log('Erfolgreich eingeloggt');
            navigate('/'); // Zur Startseite umleiten
        } catch (error) {
            showNotification('Codeeinlösung nicht möglich.', 'danger');
            setCode('');
            console.error('Fehler beim Login:', error);
        }
    };

    return (
        <div className='main-container'>
        <form onSubmit={handleSubmit}>
            <h2>Gib hier deinen Freischaltcode ein</h2>
            <input
                className='mt1'
                name="code"
                type="text"
                placeholder="Freischaltcode"
                value={code}
                onChange={(e) => setCode(e.target.value)}
            /> <br/>
            <Button className="mt1" type="submit">Abschicken</Button>
        </form>
        </div>
    );
}

export default Code;
