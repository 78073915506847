import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../auth/AuthContext';

const formatTime = (seconds) => {
    const pad = (num) => num.toString().padStart(2, '0');

    const hours = pad(Math.floor(seconds / 3600));
    const minutes = pad(Math.floor((seconds % 3600) / 60));
    const secondsLeft = pad(seconds % 60);

    return `${hours}:${minutes}:${secondsLeft}`;
};

const TokenCountdown = () => {
    const [timeLeft, setTimeLeft] = useState(0);
    const { expiryTime } = useContext(AuthContext);

    useEffect(() => {
        if (expiryTime) {
            // Update die verbleibende Zeit jede Sekunde
            const interval = setInterval(() => {
                const currentTime = Math.floor(Date.now() / 1000);
                const updatedTimeLeft = expiryTime - currentTime;
                setTimeLeft(updatedTimeLeft);

                if (updatedTimeLeft <= 0) {
                    clearInterval(interval);
                }
            }, 1000);

            // Bereinigen des Intervals beim Unmount
            return () => clearInterval(interval);
        }
    }, [expiryTime]); // Fügen Sie expiryTime als Abhängigkeit hinzu

    const formattedTime = formatTime(Math.max(timeLeft, 0));

    return (
        <div>
            {expiryTime ? `Token gültig für: ${formattedTime} ` : 'Token abgelaufen'}
        </div>
    );

    
};

export default TokenCountdown;
