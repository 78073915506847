import React, {  } from 'react';
import { Card, Button } from 'react-bootstrap';

const EndCard = () => {

  const openExternalLink = () => {
    window.open('https://forms.gle/56TzubRSxdMGRYUC6', '_blank'); // Link zum Google Forms Feedback Formular
  };

  return (
  <div>
  <Card className="shadow-sm main-player-card pad5">
    Das war’s!
      <Card.Body>
      Um story2go weiterzuentwickeln, freuen wir uns über dein Feedback. Hast du 2 Minuten Zeit für unseren Fragebogen?
      <Button onClick={openExternalLink}>Feedback geben</Button>
        {/* Weitere Inhalte der Karte */}
      </Card.Body>
    </Card>
  </div>
  );

};

export default EndCard;
