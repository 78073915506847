import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
//import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as CurrencyEuro } from 'bootstrap-icons/icons/currency-euro.svg';
import formatDate from '../../utility/dateUtils';
import EditableField from '../../utility/EditableField';
import { useNotification } from '../../hooks/useNotifications';




import { Card, Button } from 'react-bootstrap';

const InputTypeDetail = () => {
    
    const [modelData, setInputTypeData] = useState(null);
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();

    
    const selectableProviderTypes= [
        {value: 'google', label: 'google', language:'true'}, 
        {value: 'openAI', label: 'openAI', language:'false'},
    ];

    const showFieldBasedOnProviderHas = (key) => {
        const providerType = selectableProviderTypes.find(type => type.value === modelData.provider);
        // Überprüfen, ob ein passender Provider gefunden wurde und ob language 'true' ist
        if (providerType && providerType[key] === 'true'){
            return true;
        } else {
            return false;
        }
    };


    const handleSaveChanges = (newValue, fieldName, key=0) => {
        const updatedModelData = { ...modelData };
        if (newValue === ''){
            showNotification('Feld darf nicht leer sein', 'warning');
            console.log('Feld darf nicht leer sein');
        }
        else {
            updatedModelData[fieldName] = newValue;
       }
        // Aktualisieren Sie den Zustand mit der neuen Kopie
        //console.log(modelData);
        setInputTypeData(updatedModelData);
    };

    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/models/${modelData.id}`, modelData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                //console.log(response.data.info);
                showNotification('Änderungen sepeichert', 'success');                
                //setOriginalData(modelData);
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/models/${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setInputTypeData(response.data[0]);
            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
                console.error('Fehler beim Laden der Sprache', error);
            }
        };

        fetchData();
        
    }, [id]);




    if (!modelData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Spitzname:
                    <Card.Title>
                        <h2><EditableField value={modelData.called_name} onSave={(newValue) => handleSaveChanges(newValue, 'called_name')} fieldType="text" /></h2>
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Service Provider: 
                    <EditableField 
                                value={modelData.provider} 
                                onSave={(newValue) => handleSaveChanges(newValue, 'provider')} 
                                fieldType="dropdown" 
                                dropdownOptions={selectableProviderTypes}
                             />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Name:<EditableField value={modelData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" />
                    </Card.Title>
                </Card.Body>
            </Card>
            
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Länge:<EditableField value={modelData.max_tokens} onSave={(newValue) => handleSaveChanges(newValue, 'max_tokens')} fieldType="number" minValue="0"  maxValue="5000"/>
                    </Card.Title>
                    (max_tokens zwischen 128 und 5000)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                    Temperatur: <EditableField 
                            value={modelData.temperature} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'temperature')} 
                            fieldType="range" 
                            minValue="0"  
                            maxValue="2"
                            stepSize="0.01"
                        />
                    </Card.Title>
                    (default = 1)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Stop Sequenz:<EditableField value={modelData.stop_sequence} onSave={(newValue) => handleSaveChanges(newValue, 'stop_sequence')} fieldType="text"/>
                    </Card.Title>
                    (max 4, als Array angeben z.B. ['stop','end']. optional)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                    top_p: <EditableField 
                            value={modelData.top_p} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'top_p')} 
                            fieldType="range" 
                            minValue="0"  
                            maxValue="1"
                            stepSize="0.01"
                        />
                    </Card.Title>
                    (default = 1 / optional)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                    presence_penalty: <EditableField 
                            value={modelData.presence_penalty} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'presence_penalty')} 
                            fieldType="range" 
                            minValue="0"  
                            maxValue="2"
                            stepSize="0.01"
                        />
                    </Card.Title>
                    (default = 0 / optional)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                    frequency_penalty: <EditableField 
                            value={modelData.frequency_penalty} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'frequency_penalty')} 
                            fieldType="range" 
                            minValue="0"  
                            maxValue="2"
                            stepSize="0.01"
                        />
                    </Card.Title>
                    (default = 0 / optional)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Preis: 
                        {Array.from({ length: modelData.price }, (_, i) => <CurrencyEuro key={i} />)}
                        <EditableField 
                            value={modelData.price} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'price')} 
                            fieldType="range" 
                            minValue="1"  
                            maxValue="5"
                        />
                    </Card.Title>
                </Card.Body>
            </Card>
                

            <p>Letztes Update: {formatDate(modelData.updated_at)}</p>
            <p>erstellt: {formatDate(modelData.created_at)}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>

        </div>

    );
};

export default InputTypeDetail;

