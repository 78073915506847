import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useNotification } from '../../hooks/useNotifications';




import { Card } from 'react-bootstrap';

const FeedbackDetail = () => {
    
    const [feedbackData, setFeedbackData] = useState(null);
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();

    




    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/feedback/${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setFeedbackData(response.data[0]);
                console.log(response.data[0]);
            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
            }
        };

        fetchData();
        
    }, [id]);




    if (!feedbackData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Feedback:
                    <Card.Title>
                        {feedbackData.feedback_text}
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Kontakt:
                    <Card.Title>
                        {feedbackData.contact}
                    </Card.Title>
                </Card.Body>
            </Card>


        </div>

    );
};

export default FeedbackDetail;

