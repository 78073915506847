import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {BsStarFill} from 'react-icons/bs';
import { useNotification } from '../hooks/useNotifications';

const RatingStars = ({ style, processId, fetchRating=false}) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const { showNotification } = useNotification();

  const getRating = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/rating/${processId}`, {
        headers: {
          'Content-Type': 'application/json',
          // 'authorization': `Bearer ${token}`,
        },
      });
      if (response.status === 201 && response.data) {
        setRating(response.data.rating); // Stellen Sie sicher, dass Sie das richtige Feld verwenden
      } else {
        console.error('Fehler beim Abrufen der Bewertung für ProzessId ' + processId);
      }
    } catch (error) {
      console.error('Fehler beim Senden der Anfrage', error);
    }
  };

  useEffect(() => {
    if (fetchRating){
      getRating();
    }
  }, []);

  const sendRating = async (ratingValue, processId) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/rating`,
        {processId:processId, rating:ratingValue},
         {
            headers: {
                'Content-Type': 'application/json',
                //'authorization': `Bearer ${token}`,
            },
        });        
        // Überprüfen Sie den Statuscode und handeln Sie entsprechend
        if (response.status === 201) {
            showNotification('Bewertung gespeichert', 'success');                
            setRating(ratingValue);
        } else {
            showNotification('Fehler beim Speichern der Bewertung...', 'danger');
            console.error('Fehler beim Speichern der Daten');
        }
    } catch (error) {
        showNotification('Netzwerkfehler', 'danger');
        console.error('Fehler beim Senden der Anfrage', error);
    }
};

const handleRating = async (ratingValue, processId) => {
    sendRating(ratingValue, processId);
}

  return (
    <div>
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;
        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue || rating}
              onClick={() =>handleRating(ratingValue, processId)}
              style={{ display: 'none' }}
            />
            <BsStarFill
              className="star"
              color={ratingValue <= (hover || rating) ? "var(--rating-yellow)" : "var(--sd-06)"}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              style={{ ...style }}
            />
          </label>
        );
      })}
    </div>
  );
};

export default RatingStars;
