import React , {useState} from 'react';
import { FaPlay, FaPause } from 'react-icons/fa';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {Button} from 'react-bootstrap';

const RingAudioPlayer = ({ isPlaying, progress, onTogglePlay, onStopPlay }) => {

    const [lastTap, setLastTap] = useState(null);
    const [touchInitiated, setTouchInitiated] = useState(false);

    const handleDoubleTap = () => {
        const now = Date.now();
        const DOUBLE_TAP_DELAY = 300; // 300ms für Doppel-Tap
        if (lastTap && (now - lastTap) < DOUBLE_TAP_DELAY) {
            // Doppel-Tap erkannt
            onStopPlay();
            setLastTap(null);
        } else {
            // Möglicher einfacher Tap
            setLastTap(now);
            onTogglePlay();
        }
    };

    const handleTouchEnd = () => {
        setTouchInitiated(true);
        handleDoubleTap();
    };

    const handleClick = () => {
        // Verhindern, dass Klick-Ereignis ausgelöst wird, wenn es direkt nach einem Touch-Ereignis kommt
        if (!touchInitiated) {
            onTogglePlay();
        }
        setTouchInitiated(false);
    };

    return (
        <span>
        <div style={{ 
            width: '10vw', 
            height: '10vw', 
            position: 'relative', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center' 
        }}>
            <CircularProgressbar
                strokeWidth={18}
                value={progress}
                styles={buildStyles({
                    pathTransition: 'none',
                    strokeLinecap: 'butt',
                    textSize: '6vw',
                    pathColor: `var(--sd-08)`,
                    textColor: '#f88',
                    trailColor: 'var(--sd-10)',
                    backgroundColor: '#3e98c7',
                })}
            />
            <Button 
                style={{
                        position: 'absolute',
                        border: 'none',
                        backgroundColor: 'transparent',
                        color: isPlaying ? "var(--sd-02)" : "var(--sd-03)",
                        fontSize: '4.7vw', // Relative Einheit
                        cursor: 'pointer',
                        padding: '0.5em', // Relative Einheit
                    }}
                    onClick={handleClick}
                    onDoubleClick={onStopPlay}
                    onTouchEnd={handleTouchEnd}
            >
                <div style={{ 
                    position: 'relative', 
                    top: '-0.1em', 
                    left: isPlaying ? '0' : '0.1em' // Verschiebt das Play-Icon leicht nach rechts
                }}>
                    {isPlaying ? <FaPause size="1em" /> : <FaPlay size="1em" />}
                </div>
            </Button>
        </div>
        </span>
    );
};

export default RingAudioPlayer;

