import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as CurrencyEuro } from 'bootstrap-icons/icons/currency-euro.svg';
import formatDate from '../../utility/dateUtils';
import EditableField from '../../utility/EditableField';
import { useNotification } from '../../hooks/useNotifications';




import { Card, Button, Form } from 'react-bootstrap';

const PromoCodeDetail = () => {
    
    const [modelData, setPromoCodeData] = useState(null);
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();

    

    const handleSaveChanges = (newValue, fieldName, key=0) => {
        const updatedPromoCodeData = { ...modelData };
        if (newValue === ''){
            showNotification('Feld darf nicht leer sein', 'warning');
            console.log('Feld darf nicht leer sein');
        }
        else {
            updatedPromoCodeData[fieldName] = newValue;
       }
        // Aktualisieren Sie den Zustand mit der neuen Kopie
        //console.log(modelData);
        setPromoCodeData(updatedPromoCodeData);
    };

    const updateData = async () => {
        // Modifizieren Sie modelData vor dem Senden
        const modifiedData = { ...modelData };
    
        // Beispiel: Datumsfelder überprüfen und formatieren
        const dateFields = ['valid_from', 'valid_till']; // Angenommene Datumsfelder
        dateFields.forEach(field => {
            if (modifiedData[field]) {
                const date = new Date(modifiedData[field]);
                modifiedData[field] = date.toISOString().split('T')[0] + ' ' + date.toTimeString().split(' ')[0];
            }
        });
    
        // Weitere Validierungen und Modifikationen hier durchführen
    
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/promocodes/${modifiedData.id}`, modifiedData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                showNotification('Änderungen gespeichert', 'success');                
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/promocodes/${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setPromoCodeData(response.data[0]);
            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
                console.error('Fehler beim Laden der Sprache', error);
            }
        };

        fetchData();
        
    }, [id]);




    if (!modelData) return <div>Laden...</div>;

    return (
        <div>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Name:<EditableField value={modelData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" />
                    </Card.Title>
                </Card.Body>
            </Card>
            
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Code:<EditableField value={modelData.code} onSave={(newValue) => handleSaveChanges(newValue, 'code')} fieldType="text" />
                    </Card.Title>
                    wird als URL-Parameter verwendet (also keine Umlaute, Sonderzeichen um Leerzeichen verwenden)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        maximale Nutzungen:<EditableField value={modelData.amount_max} onSave={(newValue) => handleSaveChanges(newValue, 'amount_max')} fieldType="number" minValue="0"  maxValue="1000000000"/>
                    </Card.Title>
                    (Wert zwischen 0 und 1000000000)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Promptgruppe:<EditableField value={modelData.prompt_group} onSave={(newValue) => handleSaveChanges(newValue, 'prompt_group')} fieldType="number" minValue="0"  maxValue="100000"/>
                    </Card.Title>
                    ID der Prompt Gruppe (TODO: Select mit Namen der Promptgruppe)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Rolle:<EditableField value={modelData.role} onSave={(newValue) => handleSaveChanges(newValue, 'role')} fieldType="number" minValue="2"  maxValue="100000"/>
                    </Card.Title>
                    ID Nutzerrolle 2 für Tester, 3 für Nutzer (TODO: Select mit Rollen)
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                    gültig ab: <EditableField 
                            value={formatDate(modelData.valid_from)} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'valid_from')} 
                            fieldType="date" 
                        />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                    gültig bis: <EditableField 
                            value={formatDate(modelData.valid_till)} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'valid_till')} 
                            fieldType="date" 
                        />
                    </Card.Title>
                </Card.Body>
            </Card>

            <p>Nutzungen: {modelData.amount_used} - Nutzungen übrig: {modelData.amount_max - modelData.amount_used}</p>
            <p>Letztes Update: {formatDate(modelData.updated_at)}</p>
            <p>erstellt: {formatDate(modelData.created_at)}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>

        </div>

    );
};

export default PromoCodeDetail;

