import React, { useState, useEffect, useRef } from 'react';
import { Card } from 'react-bootstrap';
import axios from 'axios';

import RingAudioPlayer from './RingAudioPlayer';
import StoryFeedback from './StoryFeedback';

const PlayerCard = ({userUUID, audiofile, title, cover, processId, onPlayAudio ,onStopAudio, currentPlayingId, currentPausedId, audioProgress, cardStatus, onPlayerEnded}) => {
    const [hasPlayerEnded, setHasPlayerEnded] = useState(false);
    const [hasPlayerStarted, setHasPlayerStarted] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false); // Zustand für Bildladestatus
    const imageRef = useRef(null); // Ref für das Bild

    const setStatus = async (status) => {
    try {
        await axios.put(process.env.REACT_APP_API_BASE_URL+'/api/analyse/playerStatus/',
        {process: processId, user: userUUID, status:status},   // in Zukunft evtl. mit Tokens arbeiten
        {
            headers: {
            // 'authorization': `Bearer ${token}`,
            }
        });
    } catch (error) {
        console.error('Netzwerkfehler:', error);
        //showNotification('Netzwerkfehler.', 'danger');
    }
  };


  useEffect(() => {  // stetze audio_player_finish in DB
    if (currentPlayingId === processId && !hasPlayerEnded && cardStatus!==2 && audioProgress > 80){
        setHasPlayerEnded(true);
        setStatus(1);
        onPlayerEnded(processId, true); // Callback aufrufen
    }
  }, [audioProgress]);

  const handlePlayerFirstStart = () => { // stetze audio_player_start in DB
    if (cardStatus===1 && !hasPlayerStarted){
      setHasPlayerStarted(true);
      setStatus(0);
    }
  }

  // Event-Handler, der aufgerufen wird, wenn das Bild geladen ist
  // ist wichtig da die initialte Containergröße 0 ist und das Coverbild nicht angezeigt wird
  const handleImageLoaded = () => {
    setImageLoaded(true);
    }
  

  return (
  <div>
  <Card className="main-player-card mt1 shadow-sm">
      <div className="main-player-header">
        {title && <h2 className="main-player-title">{title}</h2>}
        <div className="main-player-cover-container">
          {cover && (
            <Card.Img 
            variant="top" 
            src={cover}
            ref={imageRef}
            onLoad={handleImageLoaded} // onLoad Event-Handler
            className={`main-player-cover-image rad03 ${imageLoaded ? '' : 'loading'}`} // Füge eine 'loading'-Klasse hinzu, wenn das Bild nicht geladen ist
            />
          )}
          {audiofile && (
            <div className="main-player-audio-container">
              <RingAudioPlayer
                audioUrl={audiofile}
                isPlaying={currentPlayingId === processId}
                onTogglePlay={() => {
                    handlePlayerFirstStart();
                    onPlayAudio(audiofile, processId);
                  }}
                onStopPlay={() => {
                    onStopAudio();
                  }}
                  
                progress={(currentPlayingId === processId || currentPausedId === processId) ? audioProgress : 0}
              />
            </div>
          )}
        </div>
      </div>
      { (hasPlayerEnded || cardStatus===2) && (
            <StoryFeedback
                processId={processId}
                fetchRating={cardStatus===2}
            />
      )}

      <Card.Body>
  
        {/* Weitere Inhalte der Karte */}
      </Card.Body>
    </Card>
  
  </div>
  
  );

};

export default PlayerCard;
