import React, { useState, useEffect, useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css'; // Importieren des Standard-Styles
import { Link } from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../utility/dateUtils';
import SortIndicator from '../../utility/SortIndicator';
import RingAudioPlayer from '../../utility/RingAudioPlayer';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import { ReactComponent as CurrencyEuro } from 'bootstrap-icons/icons/currency-euro.svg';


const VoicesTab = () => {
    const [voices, setVoices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const token = localStorage.getItem('token');
   
    const {
        currentAudio,
        audioPlayerRef,
        progressInterval,
        audioProgress,
        currentPlayingId,
        currentPausedId,
        handlePlayAudio,
        handlePlay,
        handlePause,
        handleListen,
        handleEnded,
    } = useAudioPlayer();


    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    const sortedVoices = [...voices].sort((a, b) => {
        // Erkennen, ob der Schlüssel für eine numerische Sortierung steht
        const isNumericSort = ['price'].includes(sortConfig.key);
        const isDateSort = ['created_at'].includes(sortConfig.key);
    
        if (isDateSort) { // Datumssortierung
            const dateA = new Date(a[sortConfig.key]);
            const dateB = new Date(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (isNumericSort) { // Numerische Sortierung
            const numA = parseFloat(a[sortConfig.key]);
            const numB = parseFloat(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
        } else { // Alphabetische Sortierung für andere Felder
            return (
                sortConfig.direction === 'asc' ? 
                a[sortConfig.key].localeCompare(b[sortConfig.key]) : 
                b[sortConfig.key].localeCompare(a[sortConfig.key])
            );
        }
    });

    const addVoice = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/voices/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchVoices();
            } else {
                console.error('Fehler beim Erstellen der leeren Funltion Daten');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const deleteVoice = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/voices/${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                fetchVoices();
            } else {
                console.error('Fehler beim Erstellen der leeren Funltion Daten');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const fetchVoices = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/voices/`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            //console.log(response.data);
            setVoices(response.data);

            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden der Stimmen:', error);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchVoices();
    }, []);
    useEffect(() => {
        return () => {
            if (progressInterval) {
                clearInterval(progressInterval);
            }
        };
    }, [currentAudio, progressInterval]);

    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>Verfügbare Stimmen</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('called_name')}>
                            Spitzname
                            <SortIndicator isSorted={sortKey === 'called_name'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('provider')}>
                            Servive
                            <SortIndicator isSorted={sortKey === 'provider'} direction={sortDirection} />
                        </th>
                        <th>listen</th>
                        <th onClick={() => requestSort('price')}>
                            Preis
                            <SortIndicator isSorted={sortKey === 'price'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedVoices.map(voice => (
                        <tr key={voice.id}>
                            <td>{voice.called_name.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>
                            <td>{voice.provider.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>
                            <td>{voice.sample ?
                                <div style={{width:'70%', height:'70%'}}>
                                <RingAudioPlayer
                                
                                        audioUrl={process.env.REACT_APP_API_BASE_URL + '/api/audio/' + voice.sample}
                                        isPlaying={currentPlayingId === voice.id}
                                        onTogglePlay={() => handlePlayAudio(process.env.REACT_APP_API_BASE_URL + '/api/audio/' + voice.sample, voice.id)}
                                        progress={(currentPlayingId === voice.id || currentPausedId === voice.id) ? audioProgress : 0}
                                /> </div>: "---"}
                            </td>
                            
                            <td>{[...Array(voice.price)].map((e, i) => <CurrencyEuro key={i}/>)}</td>
                            <td>{formatDate(voice.updated_at.trim())}</td>
                            <td><Link to={`./${voice.id}`}>Details</Link></td>
                            <td><button onClick={() => deleteVoice(voice.id)}>-</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <button onClick={addVoice}>
            neue (leere) Stimme erstellen
        </button>
   
      <AudioPlayer
        ref={audioPlayerRef}
        src={currentAudio}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onListen={handleListen}
        // Weitere Event-Handler und Eigenschaften
        style={{ display: 'none' }} // Versteckt den Player
      />
    </div>

    );


};

export default VoicesTab;