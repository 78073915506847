import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams} from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../../utility/dateUtils';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import useAudioPlayer from '../../../hooks/useAudioPlayer';
import RingAudioPlayer from '../../../utility/RingAudioPlayer';


const InspectStoryline = () => {
    const { id } = useParams();
    const [storyLineElements, setStoryLineElements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const token = localStorage.getItem('token');
   
    const {
        currentAudio,
        audioPlayerRef,
        audioProgress,
        currentPlayingId,
        currentPausedId,
        handlePlayAudio,
        handlePlay,
        handlePause,
        handleListen,
        handleEnded,
        handleStop
    } = useAudioPlayer();


    const deleteStory = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                fetchStoryLineElements();
            } else {
                console.error('Beim Löschen des storyLineElements');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const fetchStoryLineElements = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stories/inspectStoryelement/${id}`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            console.log(response.data);
            setStoryLineElements(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden der User:', error);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchStoryLineElements();
    }, []);


    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
<div>
    <h2>Inspect Story Element</h2>
    <h4>{storyLineElements.title ? storyLineElements.title : '-- kein Titel --'}</h4>
    <p>ID: {storyLineElements.id}, User ID:{storyLineElements.user_id}, Prozess: {storyLineElements.process_id}, Prompt: {storyLineElements.prompt_id}, Gruppe: {storyLineElements.prompt_group}, Gruppe: {storyLineElements.storyline}</p>
    <p>prev: {storyLineElements.prev}, next: {storyLineElements.next}</p>
    {storyLineElements.audio_file && (<p>
        <audio controls src={storyLineElements.audio_file}>
            Dein Browser unterstützt das Audio-Element nicht.
        </audio></p>
    )}
    {storyLineElements.cover_file && (
        <p><img src={storyLineElements.cover_file} alt="Cover" style={{ maxWidth: '100%', height: 'auto' }} /></p>
    )}

    {storyLineElements.prompt_text && <>
        <h4>Prompt Text</h4>
        <p>{storyLineElements.prompt_text}</p>
        </>
    }

    {storyLineElements.response_text && <>
        <h4>Modellantwort</h4>
        <p>{storyLineElements.response_text}</p>
        </>
    }

</div>

    );


};

export default InspectStoryline;