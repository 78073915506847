import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import TokenCountdown from '../utility/TokenCountdown';
import UserStatus from '../utility/UserStatus';
import { jwtDecode } from 'jwt-decode';
import { ReactComponent as CloseButton } from 'bootstrap-icons/icons/x.svg';
import { AuthContext }from '../auth/AuthContext';


function Footer() {
const { user } = useContext(AuthContext);
const [showImpress, setShowImpress] = useState(false);



const token = localStorage.getItem('token');
let decodedToken;
  if (token){
    decodedToken = jwtDecode(token);
  }

  const handleShowImpress  = () => {
    setShowImpress(!showImpress);
  }


  return (
    <header className="header-footer">

      { showImpress && <div className='impress text-left mt2 mb5' style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <CloseButton
            onClick={handleShowImpress}
          />
        </div>
        <span className='text4'>Impressum:</span>
        <p>
          SBAE GmbH<br/>
          Randstr. 1<br/>
          22525 Hamburg<br/>
        </p><br/>
        <p>
          <Link
            to='#'
            onClick={(e) => {
              window.location.href = 'mailto:info@sbae.de';
              e.preventDefault();
            }}
          >
            info@sbae.de
          </Link>
        </p><br/>
        <p>
          Amtsgericht Hamburg HRB 171275<br/>
          Geschäftsführerin: Sophie Burger
        </p>
      </div>}
        <div className="w100">
          <span>SBAE GmbH 2024</span>
          <span
            className='ml15' 
            onClick={handleShowImpress}
            style={{cursor: 'pointer', 'color': showImpress? 'white':'' }}
            >Impressum</span>
      </div>
      { (user && user.userrole===1) &&
        <>
          <UserStatus />
        </>
      }


    </header>
  );
}

export default Footer;


//mysqldump -u storyuser -p --no-data storydb > database_schema.sql