import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ReactComponent as DiceIcon } from 'bootstrap-icons/icons/dice-5.svg';
import formatDate from '../../../utility/dateUtils';
import EditableField from '../../../utility/EditableField';


const selectableFileTypes= [
    {value: 'string', label: 'string', sample:['test', 'foo', 'bar', '123']}, 
    {value: 'int', label: 'int', sample:[1,2,3,4,5,5356]},
    {value: 'float', label: 'float', sample:[0.3,2.4,3.6,3,94.33,0.435]}, 
    {value: 'geocoordinate', label: 'geocoordinate', sample:['[48.1234,9.34]','[49.454,10.367]']}, 
    {value: 'file', label:'file', sample:['test.pdf']}
];

const FunctionDetail = () => {
    
    const [functionData, setFunctionData] = useState(null);
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const [newParameter, setNewParameter] = useState({ name: 'new_var', type: 'int' });

    const [parameterValues, setParameterValues] = useState({});
    
    // Aktualisieren der Parameterwerte
    const handleParameterValueChange = (paramName, value) => {
        setParameterValues(prevValues => ({
            ...prevValues,
            [paramName]: value
        }));
    };

    const setRandomParameterValue = (paramType, paramName) => {
        const fileType = selectableFileTypes.find(type => type.value === paramType);
        if (fileType && fileType.sample.length > 0) {
            const randomSample = fileType.sample[Math.floor(Math.random() * fileType.sample.length)];
            handleParameterValueChange(paramName, randomSample);
        }
    };

    const executeFunction = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/functions/execute/${functionData.id}`, {
                parameters: parameterValues,
            });
            console.log(response.data);
            document.getElementById("functionOutput").innerHTML=response.data;
            // Hier das Ergebnis verarbeiten
        } catch (error) {
            console.error('Fehler beim Ausführen der Funktion', error);
        }
    };

    const addParameter = () => {
        const newparameter ={name: newParameter.name, type: newParameter.type};
        functionData.parameters.push(newparameter);
        setFunctionData(functionData);
        setNewParameter({ name: '', type: '' }); // Zurücksetzen der Eingabefelder
    };

    const removeParameter = (index) => {
        // Erstellen Sie eine Kopie von functionData
        const updatedFunctionData = { ...functionData };
    
        // Erstellen Sie eine Kopie von parameters und entfernen Sie das Element
        updatedFunctionData.parameters = updatedFunctionData.parameters.filter((_, i) => i !== index);
    
        // Aktualisieren Sie den Zustand mit der neuen Kopie
        setFunctionData(updatedFunctionData);
    };

    const handleSaveChanges = (newValue, fieldName, key=0) => {
        const updatedFunctionData = { ...functionData };
        if (newValue === ''){
            console.log('Feld darf nicht leer sein');
        }
        else if (fieldName === 'paramname'){
            updatedFunctionData['parameters'][key]['name'] = newValue;
        } else if (fieldName === 'paramtype'){
            updatedFunctionData['parameters'][key]['type'] = newValue;
        } else if (fieldName === 'paramtype'){
            newParameter.name=newValue;
        }
        else {
        updatedFunctionData[fieldName] = newValue;
       }
        // Aktualisieren Sie den Zustand mit der neuen Kopie
        setFunctionData(updatedFunctionData);
    };

    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/functions/${functionData.id}`, functionData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                console.log(response.data.info)
                //setOriginalData(functionData);
            } else {
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    useEffect(() => {
        

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/functions/${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setFunctionData(response.data[0]);
                //console.log(response.data[0].parameters)
            } catch (error) {
                console.error('Fehler beim Laden der Funktion', error);
            }
        };

        fetchData();

        
    }, [id]);

    if (!functionData) return <div>Laden...</div>;

    return (
        <div>
            <h1><EditableField value={functionData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" /></h1>
            <EditableField value={functionData.description} onSave={(newValue) => handleSaveChanges(newValue, 'description')} fieldType="textarea" />
            
            <div>
            <h4>Parameter der Funktion</h4>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Typ</th>
                        <th>change</th>
                    </tr>
                </thead>
                <tbody>
                    {functionData.parameters.map((param, index) => (
                        <tr key={index}>
                            <td><span className="code"><EditableField value={param.name} onSave={(newValue) => handleSaveChanges(newValue, 'paramname', index)} fieldType="text"  /></span></td>
                            <td>
                            <span className="code">
                            <EditableField 
                                value={param.type} 
                                onSave={(newValue) => handleSaveChanges(newValue, 'paramtype', index)} 
                                fieldType="dropdown" 

                                dropdownOptions={selectableFileTypes}
                             />
                             </span>
                            </td>
                            <td><button onClick={() => removeParameter(index)}>-</button></td>
                        </tr>
                    ))}
                <tr>
                <td><span className="code">
                        <input
                            type="text"
                            value={newParameter.name}
                            onChange={(e) => setNewParameter({ ...newParameter, name: e.target.value })}
                        />
                    </span>
                    </td>
                    <td>
                    <span className="code">
                        <select 
                            value={newParameter.type}
                            onChange={(e) => setNewParameter({ ...newParameter, type: e.target.value })}
                        >
                            {selectableFileTypes.map((type) => (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </span>
                    </td>
                    <td><button onClick={addParameter}>+</button></td>
                </tr>
                </tbody>
            </table>
            {/* ... weitere Inhalte ... */}
            </div>
            <span className="code">
            <EditableField value={functionData.code} onSave={(newValue) => handleSaveChanges(newValue, 'code')} fieldType="textarea" className="code-textarea" />
            </span>
            <p>Letztes Update: {formatDate(functionData.updated_at)}</p>
            <p>erstellt: {formatDate(functionData.created_at)}</p>
        
        
        <button onClick={updateData}>
            Änderungen speichern
        </button>

        <div>
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Variable</th>
                        <th>Wert</th>
                        <th>zufälliger Wert</th>
                    </tr>
                </thead>
                <tbody>
                {functionData.parameters.map((param, index) => (
                    <tr key={index}>
                        <td>{param.name}</td>
                        <td><input type="text" value={parameterValues[param.name] || ''} onChange={e => handleParameterValueChange(param.name, e.target.value)} /></td>
                        <td><DiceIcon className='editicon' onClick={() => setRandomParameterValue(param.type, param.name)} /></td>
                    </tr>
                ))
                }
                    <tr>
                        <td>Output</td>
                        <td id="functionOutput" ></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <button onClick={executeFunction}>
            Funktion testen
        </button>

        <div className="functionReturn"></div>
        </div>

    );
};

export default FunctionDetail;