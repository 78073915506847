import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import MainPlayer from '../components/common/MainPlayer';
import { AuthContext } from '../components/auth/AuthContext';
import { Link } from 'react-router-dom';

function HomePage() {
  const { user} = useContext(AuthContext);

  if (!user || !localStorage.getItem('token')) {
    return(
      <div className='main-container'>
        <div><h2>Hinweis</h2></div>
        <div>
          Du musst dich anmelden, um diese Anwendung zu nutzen. Verwende dafür deine. Verwende dafür deine <Link to="./login">Zugangsgaten</Link> oder einen <Link to="./code">Freischatcode</Link>.
        </div>
      </div>
    ) ; 
  }


  return (
    <div>
      <Helmet>
        <title>story2go</title>
        <meta name="description" content="Eine kleine aber feine Anwendung" />
      </Helmet>
      {user ? 
        <MainPlayer userUUID={user.userid} /> : <div className='main-container'> Die Cookies werden benötigt </div>
      }
    </div>
  );
}

export default HomePage;