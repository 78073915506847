import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import formatDate from '../../../utility/dateUtils';
import EditableField from '../../../utility/EditableField';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as Add } from 'bootstrap-icons/icons/plus.svg';
import { useNotification } from '../../../hooks/useNotifications';
import { Card, Button } from 'react-bootstrap';

const PromptDetail = () => {
    
    const [groupData, setgroupData] = useState(null);
    const [prompts, setPrompts] = useState([]);
    
    const [promptModules, setPromptModules] = useState([]);
    
    const [selectedPromptId, setselectedPromptId] = useState("");
    // Filtern von Modulen, die zum aktuellen Prompt gehören
    const [filteredPropmpts, setfilteredPropmpts] = useState([]);
    // Filtern von Modulen, die nicht zum aktuellen Prompt gehören
    const [nonReferencedModules, setNonReferencedModules] = useState([]);


    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();
    const apiEndpoint = "/api/prompts/groups/";

    // Funktion zum Aktualisieren der gefilterten Module
    const updateFilteredPropmpts = useCallback(() => {
        const filtered = prompts.filter(prompt => 
            promptModules.some(promptModule => promptModule.id === prompt.id)
        );
        setfilteredPropmpts(filtered);
        const nonReferenced = prompts.filter(prompt => 
            !promptModules.some(promptModule => promptModule.id === prompt.id)
        );
        setNonReferencedModules(nonReferenced);
    }, [prompts, promptModules]);


    const handleAddPrompt = async (moduleid) => {
        try {
            //const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}/modules/${moduleid}`, {}, {
            //    headers: {
            //        'authorization': `Bearer ${token}`,
            //    }
            //});

            setPromptModules(prevModules => {
                const newModules = [...prevModules];
                const moduleToAdd = { prompt_id: parseInt(id), promptModule: parseInt(moduleid)};
                if (moduleToAdd) {
                    newModules.push(moduleToAdd);
                }
                return newModules;
            });

            updateFilteredPropmpts();
            showNotification('Modul erfolgreich hinzugefügt', 'success');
    
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Moduls:', error);
            showNotification('Fehler beim Hinzufügen des Moduls', 'danger');
        }
    };

    const handleDeletePrompt = async (moduleid) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}/modules/${moduleid}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            // Modul aus promptModules entfernen
            setPromptModules(prevModules => prevModules.filter(module => module.input_module_id !== parseInt(moduleid)));
            updateFilteredPropmpts();

            showNotification('Modul erfolgreich entfernt', 'success');
        } catch (error) {
            console.error('Fehler beim Laden des Modelle:', error);
            showNotification('Fehler beim Hinzufügen des Moduls', 'danger');
        }
    };



    const fetchPrompts = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+'/api/prompts',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setPrompts(response.data);
            const filteredPrompts = response.data.filter(prompt => prompt.promtgroup === 3);
            setPromptModules(filteredPrompts);
            console.log(filteredPrompts);
            console.log(promptModules);
        } catch (error) {
            console.error('Fehler beim Laden der Module:', error);
            showNotification('Fehler beim Laden der Module', 'danger');
        }
    };

    // useEffect, der auf Änderungen in promptModules und modules reagiert
    useEffect(() => {
        updateFilteredPropmpts();
    }, [promptModules, prompts, updateFilteredPropmpts]);


    const handleSaveChanges = (newValue, fieldName, key=0) => {
        const updatedModelData = { ...groupData };
        if (newValue === ''){
            showNotification('Feld darf nicht leer sein', 'warning');
            console.log('Feld darf nicht leer sein');
        }
        else {
            updatedModelData[fieldName] = newValue;
       }
        setgroupData(updatedModelData);
    };


    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${groupData.id}`, groupData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                showNotification('Änderungen sepeichert', 'success');                
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setgroupData(response.data[0]);
            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
                console.error('Fehler beim Laden der Prompts', error);
            }
        };

        fetchData();
    }, [id]);


    useEffect(() => {
        fetchPrompts();

    }, []); 

    if (!groupData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Name:
                    <Card.Title>
                        <h2><EditableField value={groupData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" /></h2>
                    </Card.Title>
                </Card.Body>
            </Card>


            <Card className="my-1 shadow-sm danger">
                <Card.Body>
                    <Card.Title>
                        System Prompt: <EditableField value={groupData.system_prompt ? groupData.system_prompt : ""} onSave={(newValue) => handleSaveChanges(newValue, 'system_prompt')} fieldType="textarea" /> 
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm danger">
                <Card.Body>
                    <Card.Title>
                        Startprompt: <EditableField value={groupData.start_prompt ? groupData.start_prompt : ""} onSave={(newValue) => handleSaveChanges(newValue, 'start_prompt')} fieldType="number" /> 
                    </Card.Title>
                    ID des Startprompts (WICHTIG)
                </Card.Body>
            </Card>



            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Prompts:
                    </Card.Title>
                    {filteredPropmpts.map(module => (
                        <Card key={module.id}>
                            <Card.Body>
                                <div className="d-flex justify-content-between align-items-center">
                                    <Card.Title>
                                        {module.called_name ? module.called_name : ""}
                                    </Card.Title>
                                    <Button onClick={() => handleDeletePrompt(module.id)} >
                                        <Trash/>
                                    </Button> 
                                </div>
                                Variablen: {module.placeholder ? module.placeholder : ""}
                            </Card.Body>
                        </Card>
                    ))}

                        <Card>
                            <Card.Body>
                                <Card.Title>
                                    Neuer Prompt:
                                    {nonReferencedModules ? <select onChange={(e) => setselectedPromptId(e.target.value)} value={selectedPromptId}>
                                        <option value="">--bitte auswählen--</option>
                                        {nonReferencedModules.map(option => (
                                            <option key={option.id} value={option.id}>{option.called_name}</option>
                                        ))} 
                                    </select>  : ""}  
                                    {selectedPromptId==="" ? "" : <Button onClick={() => handleAddPrompt(selectedPromptId)} ><Add/></Button>}
                                

                                </Card.Title>
                            </Card.Body>
                        </Card>
                </Card.Body>
            </Card> 




            <p>Letztes Update: {groupData.updated_at ? formatDate(groupData.updated_at) : ""}</p>
            <p>erstellt: {groupData.created_at ? formatDate(groupData.created_at): ""}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>

        </div>

    );
};

export default PromptDetail;

