import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../../utility/dateUtils';
import SortIndicator from '../../../utility/SortIndicator';
import { useNotification } from '../../../hooks/useNotifications';


const PromptTab = () => {
    const [models, setModules] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');

    const apiEndpoint = "/api/prompts/groups/";
   
    
    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    const sortedModules = [...models].sort((a, b) => {
        // Erkennen, ob der Schlüssel für eine numerische Sortierung steht
        const isNumericSort = ['promtgroup','start_prompt'].includes(sortConfig.key);
        const isDateSort = ['created_at'].includes(sortConfig.key);
    
        if (isDateSort) { // Datumssortierung
            const dateA = new Date(a[sortConfig.key]);
            const dateB = new Date(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (isNumericSort) { // Numerische Sortierung
            const numA = parseFloat(a[sortConfig.key]);
            const numB = parseFloat(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
        } else { // Alphabetische Sortierung für andere Felder
            return (
                sortConfig.direction === 'asc' ? 
                a[sortConfig.key].localeCompare(b[sortConfig.key]) : 
                b[sortConfig.key].localeCompare(a[sortConfig.key])
            );
        }
    });

    const addModule = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_BASE_URL+apiEndpoint, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200 || response.status === 201) {
                fetchModules();
                showNotification('leeres Modell erfolgreich erstellt', 'success');
            } else {
                console.error('Fehler beim Erstellen des leeren Modells');
                showNotification('Fehler beim Erstellen des leeren Modells', 'danger');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const deleteModule = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200 || response.status === 201) {
                fetchModules();
                showNotification('Modell erfolgreich gelöscht', 'success');
            } else {
                console.error('Fehler beim löschen des Modells');
                showNotification('Fehler beim Senden der Anfrage', 'danger');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
            showNotification('Fehler beim Senden der Anfrage', 'danger');
        }
    };

    const fetchModules = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_BASE_URL+apiEndpoint,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            //console.log(response.data);
            setModules(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden des Modelle:', error);
            showNotification('Fehler beim Laden des Modelle', 'danger');
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchModules();
    }, []);


    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>Prompt Gruppen</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('name')}>
                        Name
                            <SortIndicator isSorted={sortKey === 'name'} direction={sortDirection} />
                        </th>

                        <th onClick={() => requestSort('start_prompt')}>
                        Start Prompt
                            <SortIndicator isSorted={sortKey === 'start_prompt'} direction={sortDirection} />
                        </th>

                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedModules.map(module => (
                        <tr key={module.id}>
                            <td>{module.name.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>
                            <td>{module.start_prompt}</td>
                            <td>{formatDate(module.created_at.trim())}</td>
                            <td><Link to={`./${module.id}`}>Details</Link></td>
                            <td><button onClick={() => deleteModule(module.id)}>-</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <button onClick={addModule}>
            neues (leeres) Modell erstellen
        </button>
   
    </div>

    );


};

export default PromptTab;