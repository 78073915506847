import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as CurrencyEuro } from 'bootstrap-icons/icons/currency-euro.svg';
import formatDate from '../../utility/dateUtils';
import EditableField from '../../utility/EditableField';
import AudioDropzone from '../../utility/FileDropzone';
import { useNotification } from '../../hooks/useNotifications';

import RingAudioPlayer from '../../utility/RingAudioPlayer';
import useAudioPlayer from '../../hooks/useAudioPlayer';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

import { Card, Button, Form } from 'react-bootstrap';

const VoiceDetail = () => {
    
    const [voiceData, setVoiceData] = useState(null);
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();

    const {
        currentAudio,
        audioPlayerRef,
        progressInterval,
        audioProgress,
        currentPlayingId,
        currentPausedId,
        handlePlayAudio,
        handlePlay,
        handlePause,
        handleListen,
        handleEnded,
    } = useAudioPlayer();

    
    
    const selectableProviderTypes= [
        {value: 'google', label: 'Google', language:'true'}, 
        {value: 'openAI', label: 'Open AI', language:'false'},
        {value: 'dev-bypass', label: 'DEV Bypass TTS', language:'false'},
    ];

    const showFieldBasedOnProviderHas = (key) => {
        const providerType = selectableProviderTypes.find(type => type.value === voiceData.provider);
        // Überprüfen, ob ein passender Provider gefunden wurde und ob language 'true' ist
        if (providerType && providerType[key] === 'true'){
            return true;
        } else {
            return false;
        }
    };

    const handleFileUploadSuccess = (uploadedData) => {
        setVoiceData({ ...voiceData, sample: uploadedData.filename });
      };

    const handleDelete = async (fileName, filepath, id) => {
        try {
            const response = await axios.delete(filepath + fileName, { 
                data: { id: id }
            });
            if (response.status===201){
                setVoiceData({ ...voiceData, sample: '' });
                showNotification('Datei erfolgreich gelöscht.', 'success')
            } else {
                showNotification('Fehler beim Löschen der Datei.', 'danger')
            }
        } catch (error) {
            console.error('Fehler beim Löschen der Datei:', error);
            showNotification('Serverfehler beim Löschen der Datei.', 'danger')
        }
    };

    const handleSaveChanges = (newValue, fieldName, key=0) => {
        const updatedVoiceData = { ...voiceData };
        if (newValue === ''){
            showNotification('Feld darf nicht leer sein', 'warning');
            console.log('Feld darf nicht leer sein');
        }
        else {
            updatedVoiceData[fieldName] = newValue;
       }
        // Aktualisieren Sie den Zustand mit der neuen Kopie
        //console.log(voiceData);
        setVoiceData(updatedVoiceData);
    };

    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/voices/${voiceData.id}`, voiceData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                //console.log(response.data.info);
                showNotification('Änderungen sepeichert', 'success');                
                //setOriginalData(voiceData);
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/voices/${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setVoiceData(response.data[0]);
                //console.log(response.data[0]);
            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
                console.error('Fehler beim Laden der Sprache', error);
            }
        };

        fetchData();
        
    }, [id]);

    useEffect(() => {
        return () => {
            if (progressInterval) {
                clearInterval(progressInterval);
            }
        };
    }, [currentAudio, progressInterval]);


    if (!voiceData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Spitzname:
                    <Card.Title>
                        <h2><EditableField value={voiceData.called_name} onSave={(newValue) => handleSaveChanges(newValue, 'called_name')} fieldType="text" /></h2>
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Service Provider: 
                    <EditableField 
                                value={voiceData.provider} 
                                onSave={(newValue) => handleSaveChanges(newValue, 'provider')} 
                                fieldType="dropdown" 
                                dropdownOptions={selectableProviderTypes}
                             />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Name:<EditableField value={voiceData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" />
                    </Card.Title>
                </Card.Body>
            </Card>
            
            {showFieldBasedOnProviderHas('language') ?
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Sprache: <EditableField value={voiceData.language} onSave={(newValue) => handleSaveChanges(newValue, 'language')} fieldType="text" />
                        </Card.Title>
                </Card.Body>
            </Card>
            : null
            }

        
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>Sample: 
                    <span className="editable-field">
                    {voiceData.sample ?
                        <RingAudioPlayer
                                audioUrl={process.env.REACT_APP_API_BASE_URL + '/api/audio/' + voiceData.sample}
                                isPlaying={currentPlayingId === voiceData.id}
                                onTogglePlay={() => handlePlayAudio(process.env.REACT_APP_API_BASE_URL +'/api/audio/' + voiceData.sample, voiceData.id)}
                                progress={(currentPlayingId === voiceData.id || currentPausedId === voiceData.id) ? audioProgress : 0}
                        /> : "---"}
                    </span> 
                    {voiceData.sample ? <Button onClick={() => handleDelete(voiceData.sample, process.env.REACT_APP_API_BASE_URL + '/api/voices/audiosample/', id)} ><Trash/></Button> : ''}
                        <AudioDropzone 
                            uploadroute={process.env.REACT_APP_API_BASE_URL + 'api/voices/audiosample'}
                            context={JSON.stringify({id:id})}
                            fileType={'audio'}
                            maxFiles={1}
                            maxSize={10} // in 10 MB
                            onUploadSuccess={handleFileUploadSuccess}
                        />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Preis: 
                        {Array.from({ length: voiceData.price }, (_, i) => <CurrencyEuro key={i} />)}
                        <EditableField 
                            value={voiceData.price} 
                            onSave={(newValue) => handleSaveChanges(newValue, 'price')} 
                            fieldType="range" 
                            minValue="1"
                            maxValue="5"
                        />
                    </Card.Title>
                </Card.Body>
            </Card>
                

            <p>Letztes Update: {formatDate(voiceData.updated_at)}</p>
            <p>erstellt: {formatDate(voiceData.created_at)}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>


        <Button onClick={() => updateData} >
            Änderungen speichern
        </Button>

        <Button onClick={() => showNotification('success', 'success')} >
            Erfolg
        </Button>
        <Button onClick={() => showNotification('Das ist eine wirklich lange Notification schlwarningecht', 'warning')} >
            Warnung
        </Button>
        <Button onClick={() => showNotification('info', 'info')} >
            Info
        </Button>


        <AudioPlayer
        ref={audioPlayerRef}
        src={currentAudio}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onListen={handleListen}
        // Weitere Event-Handler und Eigenschaften
        style={{ display: 'block' }} // Versteckt den Player
      />
        </div>

    );
};

export default VoiceDetail;

