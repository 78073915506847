import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../utility/dateUtils';
import SortIndicator from '../../utility/SortIndicator';
import { useNotification } from '../../hooks/useNotifications';
import { ReactComponent as CurrencyEuro } from 'bootstrap-icons/icons/currency-euro.svg';


const ModelsTab = () => {
    const [models, setModels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');
   

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    const sortedModels = [...models].sort((a, b) => {
        // Erkennen, ob der Schlüssel für eine numerische Sortierung steht
        const isNumericSort = ['price'].includes(sortConfig.key);
        const isDateSort = ['created_at'].includes(sortConfig.key);
    
        if (isDateSort) { // Datumssortierung
            const dateA = new Date(a[sortConfig.key]);
            const dateB = new Date(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (isNumericSort) { // Numerische Sortierung
            const numA = parseFloat(a[sortConfig.key]);
            const numB = parseFloat(b[sortConfig.key]);
            return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
        } else { // Alphabetische Sortierung für andere Felder
            return (
                sortConfig.direction === 'asc' ? 
                a[sortConfig.key].localeCompare(b[sortConfig.key]) : 
                b[sortConfig.key].localeCompare(a[sortConfig.key])
            );
        }
    });

    const addModel = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/models/`, {}, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchModels();
                showNotification('leeres Modell erfolgreich erstellt', 'success');
            } else {
                console.error('Fehler beim Erstellen des leeren Modells');
                showNotification('Fehler beim Erstellen des leeren Modells', 'danger');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const deleteModel = async (id) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/models/${id}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                fetchModels();
                showNotification('Modell erfolgreich gelöscht', 'success');
            } else {
                console.error('Fehler beim löschen des Modells');
                showNotification('Fehler beim Senden der Anfrage', 'danger');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
            showNotification('Fehler beim Senden der Anfrage', 'danger');
        }
    };

    const fetchModels = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/models/`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            //console.log(response.data);
            setModels(response.data);

            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden des Modelle:', error);
            showNotification('Fehler beim Laden des Modelle', 'danger');
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchModels();
    }, []);


    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>Verfügbare Modelle</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th onClick={() => requestSort('called_name')}>
                        Spitzname
                            <SortIndicator isSorted={sortKey === 'called_name'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('provider')}>
                            Servive
                            <SortIndicator isSorted={sortKey === 'provider'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('price')}>
                            Preis
                            <SortIndicator isSorted={sortKey === 'price'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedModels.map(model => (
                        <tr key={model.id}>
                            <td>{model.called_name.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>
                            <td>{model.provider.trim().replace(/(\r\n|\n|\r)/gm, "")}</td>                           
                            <td>{[...Array(model.price)].map((e, i) => <CurrencyEuro key={i}/>)}</td>
                            <td>{formatDate(model.updated_at.trim())}</td>
                            <td><Link to={`./${model.id}`}>Details</Link></td>
                            <td><button onClick={() => deleteModel(model.id)}>-</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>


            <button onClick={addModel}>
            neues (leeres) Modell erstellen
        </button>
   
    </div>

    );


};

export default ModelsTab;