import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import formatDate from '../../../utility/dateUtils';
import EditableField from '../../../utility/EditableField';
import { useNotification } from '../../../hooks/useNotifications';
import { ReactComponent as Trash } from 'bootstrap-icons/icons/trash.svg';
import { ReactComponent as Add } from 'bootstrap-icons/icons/plus.svg';

import { Card, Button, Form } from 'react-bootstrap';

const InputTypesDetail = () => {
    
    const [inputTypeData, setInputTypeData] = useState(null);
    const [inputParamsData, setInputParamsData] = useState(null);
    const [inputNewParamName, setInputNewParamName] = useState(null);
    const [inputNewDescName, setInputNewDescName] = useState(null);
    const [inputNewAppName, setInputNewAppName] = useState(null);
    const { id } = useParams();
    const token = localStorage.getItem('token');
    const { showNotification } = useNotification();

    const apiEndpoint = "/api/inputType/";
    
    const selectableProviderTypes= [
        {value: 'google', label: 'google', language:'true'}, 
        {value: 'openAI', label: 'openAI', language:'false'},
    ];


    // TODO: add functionality + routs + model
    
    const handleAddParameter = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`+'/inputtypes',
            {id:id, name:inputNewParamName, desc: inputNewDescName, app:inputNewAppName},
            {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setInputParamsData(prevModules => {
                const newModules = [...prevModules];
                const moduleToAdd = {id:response.data.insertId, input_type_id: id, name: inputNewParamName, appearances: inputNewAppName,description: inputNewDescName};
                if (moduleToAdd) {
                    newModules.push(moduleToAdd);
                }
                return newModules;
            });
 
        } catch (error) {
            console.error('Fehler beim Erstellen des Input Parameter:', error);
            showNotification('Fehler beim Laden der Input Parameter', 'danger');
        }
    };


    const handleDeleteParameter = async (paramid) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`+'/inputtypes/'+paramid,
            {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setInputParamsData(prevModules => prevModules.filter(module => module.id !== parseInt(paramid)));
 
        } catch (error) {
            console.error('Fehler beim Erstellen des Input Parameter:', error);
            showNotification('Fehler beim Laden der Input Parameter', 'danger');
        }
    };



    const handleSaveChanges = (newValue, fieldName, key=0) => {
        const updatedModelData = { ...inputTypeData };
        if (newValue === ''){
            showNotification('Feld darf nicht leer sein', 'warning');
            console.log('Feld darf nicht leer sein');
        }
        else {
            updatedModelData[fieldName] = newValue;
       }
        // Aktualisieren Sie den Zustand mit der neuen Kopie
        //console.log(inputTypeData);
        setInputTypeData(updatedModelData);
    };

    const updateData = async () => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${inputTypeData.id}`, inputTypeData, {
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                //console.log(response.data.info);
                showNotification('Änderungen sepeichert', 'success');                
                //setOriginalData(inputTypeData);
            } else {
                showNotification('Fehler beim Speichern der Daten', 'danger');
                console.error('Fehler beim Speichern der Daten');
            }
        } catch (error) {
            showNotification('Fehler beim Senden der Anfrage', 'danger');
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const fetchInpuTypeParams = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`+'/inputtypes',{
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            setInputParamsData(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Fehler beim Laden der Input Parameter:', error);
            showNotification('Fehler beim Laden der Input Parameter', 'danger');
        }
    };


    useEffect(() => {
        fetchInpuTypeParams();
    }, []); 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}${apiEndpoint}${id}`,{
                    headers: {
                        'authorization': `Bearer ${token}`,
                    }
                });
                setInputTypeData(response.data[0]);
            } catch (error) {
                showNotification('Fehler beim Senden der Anfrage', 'danger');
                console.error('Fehler beim Laden der Sprache', error);
            }
        };

        fetchData();
        
    }, [id]);


    if (!inputTypeData) return <div>Laden...</div>;

    return (
        <div>
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    Spitzname:
                    <Card.Title>
                        <h2><EditableField value={inputTypeData.called_name} onSave={(newValue) => handleSaveChanges(newValue, 'called_name')} fieldType="text" /></h2>
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Name:<EditableField value={inputTypeData.name} onSave={(newValue) => handleSaveChanges(newValue, 'name')} fieldType="text" />
                    </Card.Title>
                    Name der Komponente
                </Card.Body>
            </Card>
            
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Beschreibung:<EditableField value={inputTypeData.description} onSave={(newValue) => handleSaveChanges(newValue, 'description')} fieldType="textarea" />
                    </Card.Title>
                </Card.Body>
            </Card>

            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Parameter:
                    </Card.Title>
                    {inputParamsData ? inputParamsData.map(parameter => (
                    <Card key={parameter.id}>
                        <Card.Body>
                        <div className="d-flex justify-content-between align-items-center">
                        <Card.Title>
                            | Name: {parameter.name} 
                            | Nutzung: {parameter.description}
                            | Anzahl: {parameter.appearances}
                        </Card.Title>
                            <Button onClick={() => handleDeleteParameter(parameter.id)} >
                                        {parameter.id}<Trash/>
                                    </Button> 
                        </div>
                        </Card.Body>
                    </Card>
                    ))
                    :""}
                    <Card>
                        <Card.Body>
                        
                            Name <input type="text"
                            onChange={(e) => setInputNewParamName(e.target.value)}
                            ></input>
                            Beschreibung 
                            <input type="text"
                            onChange={(e) => setInputNewDescName(e.target.value)}
                            ></input>
                            Anzahl <input type="number" style={{ width: '3rem'}}
                            onChange={(e) => setInputNewAppName(e.target.value)}
                            ></input>
                            <div className="d-flex align-items-right">
                                {(inputNewParamName && inputNewParamName !="") ? <Button onClick={() => handleAddParameter()} ><Add/></Button> : ""}
                            </div>
                        </Card.Body>
                    </Card>
                </Card.Body>
            </Card>
            
            <Card className="my-1 shadow-sm">
                <Card.Body>
                    <Card.Title>
                        Output:<EditableField value={inputTypeData.output} onSave={(newValue) => handleSaveChanges(newValue, 'output')} fieldType="text" />
                    </Card.Title>
                    soll den Output Type angeben z.B. 'string'
                </Card.Body>
            </Card>

            <p>Letztes Update: {formatDate(inputTypeData.updated_at)}</p>
            <p>erstellt: {formatDate(inputTypeData.created_at)}</p>
        
        <Button onClick={updateData} >
            Änderungen speichern
        </Button>

        </div>

    );
};

export default InputTypesDetail;

