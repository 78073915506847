import React from 'react';
import { Card, Button } from 'react-bootstrap';

const CookieConsentPopup = ({ onAccept }) => {
  return (
    
    <>
      <div className="overlay">

    <div>
      <Card className="pad5">
      <h1>Bevor es losgeht:</h1>
      <h2>story2go befindet sich in einer geschlossenen Demo. Wir freuen uns über dein Feedback!</h2>
      <ul>
        <li>Bitte übermittle keine sensiblen Daten - alle Eingaben werden zum Zweck, eine Geschichte zu generieren, an weitere Server übertragen.</li>
        <li>Wir speichern die Daten außerdem auf einem eigenen Server, um die Nutzung zu analysieren</li>
        <li>Um den Geschichtenverlauf zu speichern, verwenden wir Cookies.</li>
      </ul>
      <p>Bitte nutze story2go nur, wenn du damit einverstanden bist.</p>
      <Button onClick={onAccept}>Einverstanden</Button>
      </Card>
    </div>
    </div>
    </>
  );
};

export default CookieConsentPopup;