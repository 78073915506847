import React, {useContext} from 'react';
import { jwtDecode } from 'jwt-decode';
import { AuthContext }from '../auth/AuthContext';
import axios from 'axios';
import { Button } from 'react-bootstrap';

const RenewToken = () => {
    const { login, user } = useContext(AuthContext); // Zugriff auf die logout-Funktion aus dem AuthContext
    const token = localStorage.getItem('token');
    const handleRenew = async (event) => {
        event.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/renewtoken`, {}, {
                headers: {
                    'authorization': `Bearer ${token}`
                }
            });
    
            localStorage.setItem('token', response.data.accessToken);
            const decodedToken = jwtDecode(response.data.accessToken);
            const expiryTime = decodedToken.exp;
            login({ username: user.username }, expiryTime); // Aktualisieren des AuthContext-Zustands
            // Weitere Aktionen nach dem Login...
        } catch (error) {
            console.error('Fehler beim Erneuern des Tokens:', error);
        }
    
    };

    return (
        <Button onClick={handleRenew}>renew Token</Button>
    );
};

export default RenewToken;



