import React, { useState, useEffect } from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { WebSocketProvider, useWebSocketContext } from '../../hooks/useWebSocket';

function createMarkup(htmlContent) {
  return { __html: htmlContent };
}

function ProgressOverlay({ visible }) {


  if (!visible) return null;

  const wsUrl = `${process.env.REACT_APP_API_WS_URL}?userId=${localStorage.getItem('userId')}`;

  return (
    <WebSocketProvider url={wsUrl}>
      <div className="overlay">
   
        <ProgressContent />
      </div>
    </WebSocketProvider>
  );
}


function ProgressContent() {
  const { progress, description } = useWebSocketContext();

  const infoTexts = [
    {
      'name':'text001', 
      'content' : 'Mit Storydive bekommt die Redewendung "der Lauf der Geschichte" eine ganz neue Bedeutung.', 
      'source':'Computer Bild über Storydive' ,
      'imageFile' : '/assets/illustrations/01.png', 
      'displayTime': 8
    },
    {
      'name':'text002',
      'content' : 'Zusammen gehen wir auf Entdeckungstour, wuseln und toben herum, und hören andächtig zu, wie die Geschichte sich entfaltet.', 
      'source':'Echt Lessig Blog der Stadt Wolfenbüttel<br/>über "Schatten über Wolfenbüttel"', 
      'imageFile' : '/assets/illustrations/02.png', 
      'displayTime': 8
    },
    {
      'name':'text003',
      'content' : 'Jeder, der Hörspiele und Erzählungen liebt, sollte diese Form des Hörspiels mal ausprobieren.', 
      'source':'Echt Lessig Blog der Stadt Wolfenbüttel<br/>über "Schatten über Wolfenbüttel"', 
      'imageFile' : '/assets/illustrations/03.png', 
      'displayTime': 8
    },
    {
      'name':'text004',
      'content' : 'Und warum nicht eine Geschichte aus dem boomenden Genre der Regionalkrimis nachspielen?', 
      'source':'Stuttgarter Zeitung über Storydive', 
      'imageFile' : '/assets/illustrations/04.png', 
      'displayTime': 8
    },
    {
      'name':'text005',
      'content' : 'Der Clou: Teilnehmer können während des Spaziergangs den Ausgang der Geschichte beeinflussen.', 
      'source':'Hannoversche Allgemeine Zeitung<br>über unseren Audiowalk "Urban Legend"', 
      'imageFile' : '/assets/illustrations/05.png', 
      'displayTime': 8
    },

    {
      'name':'text006',
      'content' : 'Digitale und \'echte\' Welt verschmelzen bei Benutzung der App zu einer neuen Art des Geschichtenerzählens.', 
      'source':'Badische Neueste Nachrichten  über Storydive', 
      'imageFile' : '/assets/illustrations/06.png', 
      'displayTime': 8
    },

    {
      'name':'text007',
      'content' : 'Die Geschichten, die über den Kopfhörer aus dem Handy kommen, sind so vielfältig wie die Stadt, in der sie spielen.', 
      'source':'Badische Neueste Nachrichten<br>über verschiedene Audiowalks der<br>Karlsruher Literaturtage', 
      'imageFile' : '/assets/illustrations/07.png', 
      'displayTime': 8
    },

    {
      'name':'text008',
      'content' : 'Podcast war gestern. Jetzt kommt der Audiowalk.', 
      'source':'merkurist.de über Storydive', 
      'imageFile' : '/assets/illustrations/08.png', 
      'displayTime': 8
    },

    {
      'name':'text009',
      'content' : 'Wer seine übliche Runde verlassen möchte, der sollte unbedingt die App Storydive ausprobieren.', 
      'source':'Isarblog über Storydive', 
      'imageFile' : '/assets/illustrations/09.png', 
      'displayTime': 8
    },

    {
      'name':'text010',
      'content' : 'Ich bin gestern im strömenden Regen eine Stunde durch Hamburg spaziert. Und das freiwillig. Und ich hatte auch noch Spaß dabei.', 
      'source':'podstars.de<br>über unseren Hamburger Audiowalk <br>"Altopien"', 
      'imageFile' : '/assets/illustrations/10.png', 
      'displayTime': 8
    },

    {
      'name':'text011',
      'content' : 'Mit spannenden Geschichten können so nicht nur Fiktion, sondern auch Fakten erlebbar gemacht werden.', 
      'source':'Kulturaspekte über Storydive', 
      'imageFile' : '/assets/illustrations/01.png', 
      'displayTime': 8
    },

    {
      'name':'text012',
      'content' : 'Wir hatten das Gefühl den Figuren zu lauschen und mit ihnen unterwegs zu sein.', 
      'source':'Eine Hörerin<br> über unseren Wolfenbütteler Audiowalk <br>"Schatten über Wolfenbüttel"', 
      'imageFile' : '/assets/illustrations/02.png', 
      'displayTime': 8
    },

    {
      'name':'text013',
      'content' : 'Echt zu empfehlen für Familien mit Kindern.', 
      'source':'Eine Hörerin über unseren Wolfenbütteler <br>Audiowalk "Schatten über Wolfenbüttel"', 
      'imageFile' : '/assets/illustrations/03.png', 
      'displayTime': 8
    },

    {
      'name':'text014',
      'content' : 'Noch nie hat ein einfacher Waldspaziergang so Spaß gemacht.', 
      'source':'Ein Hörer<br>über unseren Audiowalk "Into the Woods"', 
      'imageFile' : '/assets/illustrations/04.png', 
      'displayTime': 8
    },

    {
      'name':'text015',
      'content' : 'Ein Hörspiel hören, das "vor Ort" geschieht und die Umgebung mit einbezieht. Das ist eine geniale Idee und ist in dieser App toll umgesetzt.', 
      'source':'App-Store Bewertung für Storydive', 
      'imageFile' : '/assets/illustrations/05.png', 
      'displayTime': 8
    },

  ];

  const [currentIndex, setCurrentIndex] = useState(() => Math.floor(Math.random() * infoTexts.length));
  const [usedIndexes, setUsedIndexes] = useState([]); // Bereits verwendete Indizes

  useEffect(() => {
    const displayTime = infoTexts[currentIndex].displayTime * 1000; // Umwandlung in Millisekunden
    const timer = setTimeout(() => {
      let nextIndex;
      do {
        nextIndex = Math.floor(Math.random() * infoTexts.length);
      } while (usedIndexes.includes(nextIndex) && usedIndexes.length < infoTexts.length);
      
      const newUsedIndexes = usedIndexes.length < infoTexts.length - 1 ? [...usedIndexes, nextIndex] : [];
      setUsedIndexes(newUsedIndexes);
      setCurrentIndex(nextIndex);
      //setUsedIndexes([1]);
      //setCurrentIndex(0);
    }, displayTime);

    return () => clearTimeout(timer); // Cleanup-Funktion, um den Timer bei Bedarf zu löschen
  }, [currentIndex, infoTexts, usedIndexes]);

  return (
    <>
      <div className='wait-ani'>
        <img src={infoTexts[currentIndex].imageFile} style={{ maxHeight:'20vh' }} alt="" /><br/>
        <div className='mt2 text4 text-left' dangerouslySetInnerHTML={createMarkup(`"${infoTexts[currentIndex].content}"`)} />
        <div className='mt1 text2 text-right' dangerouslySetInnerHTML={createMarkup(infoTexts[currentIndex].source)} />
      </div>

      <div className='progress-b text3'>

        <ProgressBar className="upload-progressbar w100" variant="danger" animated now={progress} label={`${progress}%`} />
        <p className='mt1 w100'>{description} </p>
      </div>
    </>
  );
}

export default ProgressOverlay;
