import React, { useState} from 'react';
import {Button} from 'react-bootstrap';
import axios from 'axios';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useNotification } from '../hooks/useNotifications';
import RatingStars from './RatingStars';

const StoryFeedback = ({ processId, fetchRating=false }) => {
  const [feedback, setFeedback] = useState({processId:processId});
  const { showNotification } = useNotification();
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);

  const handleSaveFeedback = (newValue, fieldName) => {
    const updatedFeedback = { ...feedback };
    updatedFeedback[fieldName] = newValue;
    setFeedback(updatedFeedback);
  }

  const sendFeedback = async () => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/feedback`,
        feedback,
         {
            headers: {
                'Content-Type': 'application/json',
                //'authorization': `Bearer ${token}`,
            },
        });
        if (response.status === 201) {
  
            showNotification('Feedback abgeschickt', 'success');                
            setFeedback({processId:processId}); // reset Feedback
            setIsFeedbackOpen(false);
  
        } else {
            showNotification('Fehler beim Senden des Feedbacks...', 'danger');
            console.error('Fehler beim Speichern der Daten');
        }
    } catch (error) {
        showNotification('Netzwerkfehler', 'danger');
        console.error('Fehler beim Senden des Feedbacks', error);
    }
  };

  return (
  <div>
      <div className="main-player-feedback-container">
        <div className="main-player-rating">
          <RatingStars
            processId = {processId}
            fetchRating = {fetchRating}
          />
        </div>
        <div className="main-player-feedback" onClick={() => setIsFeedbackOpen(!isFeedbackOpen)}>
          feedback / melden {isFeedbackOpen ? <BsChevronUp /> : <BsChevronDown />}
        </div>
      </div>

      {isFeedbackOpen && (
        <div className="ml15 mr15 text3">
          Ihr Text
          <div>
            <textarea 
              className="w100"
              value={feedback['feedbackText'] || ''}
              onChange={(e) => handleSaveFeedback(e.target.value, 'feedbackText')}
            >
            </textarea>
          </div>
          <div>
            Kontaktmöglichkeit (falls gewünscht)
            <div>
              <input
                className="w100"
                value={feedback['feedbackContact'] || ''}
                onChange={(e) => handleSaveFeedback(e.target.value, 'feedbackContact')}
              ></input></div>
          </div>
          {(feedback['feedbackText']|| feedback['feedbackContact']) && ((feedback['feedbackText'].trim().length >0) || (feedback['feedbackContact'].trim().length >0)) &&(
            <Button className="text3 ml0"
            onClick={sendFeedback}>Absenden</Button>
          )}
        </div>
      )}

  </div>
  );
};

export default StoryFeedback;
