import React, { useState } from 'react';
import axios from 'axios';
import { Card, Button } from 'react-bootstrap';
import { useNotification } from '../../../hooks/useNotifications';

const AddUser = () => {
    const [userUUID, setUserUUID] = useState('');
    const [userAddStoryline, setUserAddStoryline] = useState(0);
    const { showNotification } = useNotification();
    const token = localStorage.getItem('token');

    const handleAddUser = async () => {
        const userData = {
            userUUID: userUUID.trim(),
            storyline: parseInt(userAddStoryline, 10),
        };

        try {
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/addStoryline`, userData, {
                headers: {
                    'authorization': `Bearer ${token}`,
                }
            });
            showNotification('Nutzer erfolgreich erstellt', 'success');
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Nutzers:', error);
            showNotification('Nutzer konnte nicht erstellt werden', 'danger');
        }
    };

    const enableSend = userUUID.trim().length > 0  && userAddStoryline > 0;

    return (
        <div>
            <h1>Storyline freischalten</h1>
            <Card>
                <p>Nutzer neue Storyline freischalten</p>
                Nutzer UUID: <input type="text" value={userUUID? userUUID: ''} onChange={(e) => setUserUUID(e.target.value)} />
                Storyline: <input type="number" value={userAddStoryline.toString()} onChange={(e) => setUserAddStoryline(Number(e.target.value))} />
                {enableSend && 
                    <Button onClick={handleAddUser}>
                        abschicken
                    </Button>
                }
            </Card>
        </div>
    );
};

export default AddUser;
