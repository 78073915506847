import React, { useState, useEffect, useRef } from 'react';
import { Link, useParams} from 'react-router-dom';
import axios from 'axios';
import formatDate from '../../../utility/dateUtils';
import SortIndicator from '../../../utility/SortIndicator';






const InspectStoryline = () => {
    const { storylineid } = useParams();
    const [storyLineElements, setStoryLineElements] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'asc' });
    const { key: sortKey, direction: sortDirection } = sortConfig;
    const token = localStorage.getItem('token');

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };
    
    
    const sortedStoryLines = [...storyLineElements].sort((a, b) => {
        // Erkennen, ob der Schlüssel für eine numerische oder Datumssortierung steht
        const isNumericSort = ['visits', 'role', 'id', 'user_id', 'prev', 'next','prompt_group'].includes(sortConfig.key);
        const isDateSort = ['created_at', 'last_visit'].includes(sortConfig.key);
    
        if (isDateSort) { // Datumssortierung
            const dateA = new Date(a[sortConfig.key] ?? "1970-01-01");
            const dateB = new Date(b[sortConfig.key] ?? "1970-01-01");
            return sortConfig.direction === 'asc' ? dateA - dateB : dateB - dateA;
        } else if (isNumericSort) { // Numerische Sortierung
            const numA = parseFloat(a[sortConfig.key] ?? "0");
            const numB = parseFloat(b[sortConfig.key] ?? "0");
            return sortConfig.direction === 'asc' ? numA - numB : numB - numA;
        } else { // Alphabetische Sortierung für andere Felder
            const valueA = a[sortConfig.key] ?? "0";
            const valueB = b[sortConfig.key] ?? "0";
            return sortConfig.direction === 'asc' ? 
                valueA.localeCompare(valueB) : 
                valueB.localeCompare(valueA);
        }
    });



    const deleteStory = async (storylineid) => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/api/userManager/${storylineid}`, {
                headers: {
                    'authorization': `Bearer ${token}`,
                },
            });
            // Überprüfen Sie den Statuscode und handeln Sie entsprechend
            if (response.status === 200) {
                fetchStoryLineElements();
            } else {
                console.error('Beim Löschen des storyLineElements');
            }
        } catch (error) {
            console.error('Fehler beim Senden der Anfrage', error);
        }
    };

    const fetchStoryLineElements = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/stories/inspectStoryline`,{
                headers: {
                    'authorization': `Bearer ${token}`,
                },
                params: {
                    'storyLine': storylineid,
                  }
            });
            setStoryLineElements(response.data);
            setIsLoading(false);
        } catch (error) {
            console.error('Fehler beim Laden der User:', error);
            setIsLoading(false);
        }
    };
    useEffect(() => {
        fetchStoryLineElements();
    }, []);


    if (isLoading) {
        return <div>Laden...</div>;
    }
    
    return (
        <div>
            <h2>Inspect Storyline {storylineid}</h2>
            <table className="table">
                <thead>
                    <tr>

                        <th onClick={() => requestSort('title')}>
                            title
                            <SortIndicator isSorted={sortKey === 'title'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('id')}>
                            id
                            <SortIndicator isSorted={sortKey === 'id'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('user_id')}>
                            User
                            <SortIndicator isSorted={sortKey === 'user_id'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('prompt_id')}>
                            Prompt
                            <SortIndicator isSorted={sortKey === 'prompt_id'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('prev')}>
                            prev
                            <SortIndicator isSorted={sortKey === 'prev'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('next')}>
                            next
                            <SortIndicator isSorted={sortKey === 'next'} direction={sortDirection} />
                        </th>
                        <th onClick={() => requestSort('created_at')}>
                            Erstellt am
                            <SortIndicator isSorted={sortKey === 'created_at'} direction={sortDirection} />
                        </th>
                        <th>bearbeiten</th>
                        <th>löschen</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedStoryLines.map(element => (
                        <tr key={element.id}>
                            <td>{element.title ? element.title.trim().replace(/(\r\n|\n|\r)/gm, "") : ' -- '}</td>
                            <td>{element.id}</td>
                            <td>{element.user_id}</td>
                            <td>{element.prompt_id ? element.prompt_id : ' -- '}</td>
                            <td>{element.prev}</td>
                            <td>{element.next}</td>
                            <td>{formatDate(element.created_at.trim(),'date-time')}</td>
                            <td><Link to={`../userManager/inspectStoryelement/${element.id}`}>Details</Link></td>
                            <td><button onClick={() => deleteStory(element.id)}>-</button></td>
                        </tr>
                    ))}
                </tbody>
            </table>

   

    </div>

    );


};

export default InspectStoryline;