import React from 'react';
import UserManager from './components/auth/UserManager';
import { NotificationProvider } from './components/hooks/useNotifications';
//import { WebSocketProvider } from './components/hooks/useWebSocket';
import { AuthProvider } from './components/auth/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import SignUp from './pages/SignUp';
import Login from './pages/Login';
import HomePage from './pages/HomePage';
import Code from './pages/Code';
import AdminPage from './pages/AdminPage';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

if (process.env.NODE_ENV === 'development') {
    axios.defaults.headers.common['ngrok-skip-browser-warning'] = 'true';
}

function App() {
  return (
    <AuthProvider>
    <Router>
      <UserManager />
      <NotificationProvider>
      <div>
      <div id="main-container">
        <div id="header">
          <Header />
        </div>
        <div id="content">
            <Routes>
              <Route path="/signup" element={<SignUp />} />
              <Route path="/login" element={<Login />} />
              <Route path="/code/:activationCode" element={<Code />} />
              <Route path="/code/" element={<Code />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/admin/*" element={<AdminPage />} />
              {/* Weitere Routen hier */}
          </Routes>
        </div>
        <div id="footer">
          <Footer />
        </div>
      </div>
      </div>
      </NotificationProvider>
    </Router>
    </AuthProvider>
  );
}

export default App;